import Loaging from "../../../components/Load"
import Header from "../../../components/Header/Header"
import Footer from "../../../components/Footer/Foooter"

import { Link } from "react-router-dom"
import { AuthContext } from '../../../context'
import { useEffect, useContext, useState } from "react"

export default function About() {

    const { states, loadDataSobre } = useContext(AuthContext)

    const [data, setData] = useState({})
    const [rgba1, setRgba1] = useState('')
    const [rgba2, setRgba2] = useState('')
    const [rgba3, setRgba3] = useState('')

    const hexToRGBA = (hex, alpha) => {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config'))
        setData(config)
        setRgba1(hexToRGBA(config.corPrimaria, 0.5))
        setRgba2(hexToRGBA(config.corSecundaria, 0.5))
        setRgba3(hexToRGBA(config.corTerciaria, 0.5))

    }, [])

    const load = async () => {
        await loadDataSobre()
    }

    useEffect(() => {
        load()
    }, [])

    if (states.loading) {
        return <Loaging />
    } else {
        if (Object.keys(states.dataSobre).length > 0) {
            return (
                <div>
                    <Header />
                    <div className="flex flex-col justify-center items-center p-20" style={{
                        height: '100px', // Ajuste a altura conforme necessário
                        background: `${rgba1}`
                    }}
                    >
                        <h1 className="flex flex-col items-center justify-center font-['Be Vietnam Bold'] font-bold text-white md:text-[50px] text-[30px]">Sobre Nós</h1>
                        <div className="flex flex-row bg">
                            <Link to="/" className="text-gray-200">Página inicial</Link>
                            <span className="text-white"> &rsaquo; Quem somos </span>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row">
                        <div className="flex flex-row md:w-[40%] items-center justify-center pl-4">
                            <img
                                className="w-[100%]"
                                src={states.dataSobre.foto}
                                crossOrigin="anonymous"
                            />
                        </div>
                        <div className="flex flex-row justify-start items-start md:w-[50%] pr-4 pl-4">
                            <div className="flex flex-col p-5">
                                <p className="text-justify font-['Be Vietnam Bold'] whitespace-pre-line pb-6 text-[18px] font-serif">
                                    {states.dataSobre.descricao}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center items-center p-20 h-[220px] sm:h-[100px] text-center" style={{
                         // Ajuste a altura conforme necessário
                        background: `${rgba1}`
                    }}>
                        <h1 className="flex flex-col items-center justify-center font-['Be Vietnam Bold'] font-bold text-white md:text-[50px] text-[30px]">Entre em contato conosco</h1>
                        <Link to="/contato" type="button" style={{
                            background: `${rgba2}`
                        }} className="flex flex-col justify-center inline-block sm:w-60 h-[60px] w-[190px] mt-4 px-2 py-2.5 text-white text-center font-medium text-xs leading-tight uppercase rounded-full shadow-md  hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"><span className="text-[12px]">Entrar em contato</span></Link>
                    </div>
                    <Footer />
                </div>
            )
        }
    }
}