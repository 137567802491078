import Header from '../../../components/Header/Header'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../context'
import { useNavigate, Link } from 'react-router-dom'
import './home.css'
import Footer from '../../../components/Footer/Foooter'
import Loading from '../../../components/Load'


export default function Home() {

    const history = useNavigate()

    const { states, setters, loadDataHome, loadConfig, getWithLimitBlog } = useContext(AuthContext)

    const [data, setData] = useState({})
    const [rgba1, setRgba1] = useState('')
    const [rgba2, setRgba2] = useState('')
    const [rgba3, setRgba3] = useState('')
    const [activeIndex, setActiveIndex] = useState(1)


    const hexToRGBA = (hex, alpha) => {
        var r = parseInt(hex?.slice(1, 3), 16),
            g = parseInt(hex?.slice(3, 5), 16),
            b = parseInt(hex?.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    useEffect(() => {
        let config = localStorage.getItem('config')
        //states.dataConfig
        config = JSON.parse(config)
        if (config) {
            config = config
        } else {
            config = states.dataConfig
        }
        setData(config)
        setRgba1(hexToRGBA(config.corPrimaria, 0.5))
        setRgba2(hexToRGBA(config.corSecundaria, 0.5))
        setRgba3(hexToRGBA(config.corTerciaria, 0.5))

    }, [])

    const route = () => {
        history("/sobre")
    }

    const load = async () => {
        Object.keys(states.dataLoadHome).dataConfig === 0 && await loadConfig()
        await loadDataHome()
        await getWithLimitBlog()
    }

    useEffect(() => {
        setters.setOffsetDataBlog(0)
        load()
        return () => {
            setters.setDataLoadHome({})
        }
    }, [])

    const goToPrevSlide = () => {
        let index = activeIndex;
        let dataLength = states.dataLoadHome.secao1.img.length;
        if (index < 1) {
            index = dataLength;
        }
        --index;
        setActiveIndex(index);
    };

    const goToNextSlide = () => {
        let index = activeIndex;
        let dataLength = states.dataLoadHome.secao1.img.length;
        if (index === dataLength - 1) {
            index = -1;
        }
        ++index;
        setActiveIndex(index);
    };

    const generateBanner = data => {
        return (
            <>
                <div>
                    <div className="carousel">
                        {data.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`carousel-item ${index === activeIndex ? 'block' : 'hidden'}`}
                                >
                                    <img
                                        src={item.url}
                                        className="w-full relative h-[200px] sm:h-[400px] "
                                        alt={`Slide ${index}`}
                                        crossOrigin="anonymous"
                                    />
                                </div>

                            )
                        })}
                        {data.length > 1 && (
                            <>
                                <div className="relative absolute top-1/2 transform -translate-y-1/2 h-[200px] sm:h-[400px]">
                                    <div className="flex justify-between w-full h-10">
                                        {/* <button className='text-white justify-start pl-4'>Ok1</button>
                                <button className='text-white justify-end pr-4'>OK2</button> */}
                                        <button
                                            className={'bg-white text-black flex w-10 h-10 flex-shrink-0 items-center justify-center rounded-full text-lg font-medium ml-4'}
                                            // className="carousel-control-prev bg-white p-1 text-black ml-4 "
                                            onClick={goToPrevSlide}
                                        >
                                            <span className="carousel-control-prev-icon" aria-hidden="true">{'<'}</span>
                                            <span className="sr-only">Previous</span>
                                        </button>
                                        <button
                                            className={'bg-white text-black flex w-10 h-10 flex-shrink-0 items-center justify-center rounded-full text-lg font-medium mr-4'}
                                            onClick={goToNextSlide}
                                        >
                                            <span className="carousel-control-next-icon" aria-hidden="true">{'>'}</span>
                                            <span className="sr-only">Next</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }

    const generateBannerBranding = data => {
        return (
            <>
                <div className="carousel p-8 flex sm:hidden items-center justify-center">
                    {data.map((item, index) => {
                        const isVisible = index === activeIndex;
                        return (
                            <div
                                key={index}
                                className={`carousel-item ${isVisible ? 'block' : 'hidden'} relative float-left sm:w-1/4`}
                            >
                                <img
                                    src={item.url}
                                    // src={'https://tailwindui.com/img/logos/48x48/savvycal.svg'}
                                    className="w-[90%] h-auto block rounded-md"
                                    alt={`Slide ${index}`}
                                    crossOrigin="anonymous"
                                />
                            </div>

                        )
                    })}
                </div>
                <div className="carousel p-8 sm:flex hidden items-center justify-center gap-x-4">
                    {data.map((item, index) => {
                        const isVisible = index === activeIndex || index === activeIndex + 1 || index === activeIndex + 2 || index === activeIndex + 3;
                        return (
                            <div
                                key={index}
                                className={`carousel-item ${isVisible ? 'block' : 'hidden'} relative float-left sm:w-1/4`}
                            >
                                <img
                                    src={item.url}
                                    // src={'https://tailwindui.com/img/logos/48x48/savvycal.svg'}
                                    className="w-[100%] h-auto block rounded-full"
                                    alt={`Slide ${index}`}
                                    crossOrigin="anonymous"
                                />
                            </div>

                        )
                    })}
                </div>
                {data.length > 1 && (
                    <>
                        <button
                            className="carousel-control-prev absolute top-1/2 transform -translate-y-1/2 left-4 z-10 bg-white rounded-full text-black"
                            onClick={goToPrevSlide}
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true">{'<'}</span>
                            <span className="sr-only">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next absolute top-1/2 transform -translate-y-1/2 right-4 z-10 bg-white rounded-full text-black"
                            onClick={goToNextSlide}
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true">{'>'}</span>
                            <span className="sr-only">Next</span>
                        </button>
                    </>
                )}
            </>
        );
    }

    const genSection3 = () => {

        if (states.dataLoadHome.secao3.img.length > 0) {
            return (
                <div style={{ position: 'relative' }} className='pt-8 pb-8 mt-12'>
                    <img
                        // src={'https://tailwindui.com/img/ecommerce-images/home-page-03-category-01.jpg'}
                        src={states.dataLoadHome.secao3?.img[0].url}
                        style={{
                            position: 'absolute',
                            right: 'auto',
                            bottom: 0,
                            zIndex: -1,
                            width: '100%',  // ou defina uma largura específica se necessário
                            height: '100%', // isso vai esticar a imagem para cobrir a área desejada
                        }}
                        alt="Descrição da Imagem"
                        crossOrigin="anonymous"
                    />

                    <div className='flex flex-col'>
                        <div className='flex flex-col justify-center items-center text-center'>
                            <h1 className='text-white font-["Popins"] text-[22px]'>{states.dataLoadHome.secao3.titulo.titulo}</h1>
                            <span className='text-white font-["Popins"] text-[54px]'>{states.dataLoadHome.secao3.texto.description}</span>
                        </div>
                        <div className='flex flex-col sm:flex-row justify-center items-center gap-x-4'>
                            {
                                states.dataLoadHome.secao3.numbers.map((item, index) => (
                                    <div key={index} className='flex flex-col items-center'>
                                        <span className='text-white font-["Be Vietnam Bold"] text-[70px] mb-0 text-[#379AD5]'>{item.titulo.titulo}</span>
                                        <span className='text-white font-["Be Vietnam Bold"] text-[22px] w-[55%] text-center'>{item.texto.description}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                </div>
            )
        }

    }

    if (states.loading || Object.keys(states.dataLoadHome).length === 0) {
        return (<Loading />)
    } else {
        return (
            <>
                <Header />
                {/* INICIO SECAO 1 */}
                <div className='carrousel flex justify-center w-full'>
                    <div
                        id="carouselDarkVariant"
                        className="carousel slide carousel-fade carousel-dark h-[200px] sm:h-[400px]"
                        data-bs-ride="carousel"
                        style={{ width: '100%' }} // Adjust the height as needed
                    >
                        {generateBanner(states.dataLoadHome.secao1.img)}
                    </div>
                </div>
                {/* FIM SECAO 1 */}

                {/* INICIO SECAO 2 */}
                <div className="flex flex-col md:flex-row mt-12 p-4">
                    <div className="flex flex-row md:w-[60%] items-center justify-center pl-4 mr-8">
                        {states.dataLoadHome.secao2.img.map((item, index) => (
                            <img
                                src={item.url}
                                crossOrigin="anonymous"
                                alt="..."
                                className="w-[500px] h-[250px] sm:h-[500px] "
                                key={index}
                            />
                        ))}
                    </div>

                    <div className="flex flex-row justify-start items-start w-[100%] p-4 mt-8 8">
                        <div className="flex flex-col">
                            <h2 className={`font-serif text-[18px] font["Popins"]`}>{states.dataLoadHome.secao2.subtitulo.description}</h2>
                            <h1>
                                <span className='text-[35px] md:text-[47px] lh:text-[60px] text-[#313131] font-["Be Vietnam Bold"] font-bold '>{states.dataLoadHome.secao2.titulo.titulo}</span>
                            </h1>
                            <p className='w-full text-star font-["Popins"] text-[20px]'>
                                {states.dataLoadHome.secao2.texto.titulo}
                            </p>
                            <div>
                                <button
                                    type="button"
                                    onClick={route} style={{
                                        backgroundColor: data.corPrimaria,
                                    }}
                                    className="sm:w-60 h-[60px] w-[190px] mt-4 px-2 py-2.5 text-white font-medium text-lg leading-tight uppercase rounded-full shadow-md  hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                                >
                                    {states.dataLoadHome.secao2.link.link}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='w-[90%] ml-[5%] mt-10 mb-10' />
                <div className='flex flex-col justify-center'>
                    <div className='title flex flex-col justify-center items-center p-4'>
                        <h1 className='text-blue font-["Popins"] text-[25px]'>{states.dataLoadHome.secao4?.titulo.titulo}</h1>
                        {/* <div className='flex flex-col sm:flex-row justify-center items-center'>
                            <span className='text-[#133268] font-["Be Vietnam Bold"] font-bold text-[30px] text-center'>{states.dataLoadHome.secao4?.texto.description} </span>
                        </div>
                        <p className='text-["Be Vietnam Bold"] text-[25px] text-center mt-4'>
                            {states.dataLoadHome.secao4?.subtitulo.description}
                        </p> */}
                    </div>
                    <div className='flex items-center justify-center'>
                        <div className='grid grid-cols-2 sm:grid-cols-4'>
                            {
                                states.dataLoadHome.secao4?.img.map((item, index) => {
                                    return states.dataLoadHome.secao4?.icons.map((item2, index2) => {
                                        if (index == index2) {
                                            return (
                                                <div key={index} className='col-span-2 md:col-span-2 pb-4'>
                                                    <div className='flex flex-col items-center justify-center w-[100%] pb-4 pr-4 pl-4'>
                                                        <img
                                                            src={item.url}
                                                            alt="..."
                                                            className='w-[100px] h-[100px]'
                                                            crossOrigin="anonymous"
                                                        />
                                                        <span className='text-[#133268] font-["Be Vietnam Bold"] font-bold text-[20px] text-justify'>{item2.titulo.titulo}</span>

                                                        <p className='text-[#133268] font-["Be Vietnam Bold"] font-bold text-[15px] text-justify sm:w-[350px]'>{item2.texto.description}</p>
                                                    </div>
                                                </div>
                                            )

                                        }

                                    })
                                })
                            }
                        </div>
                    </div>
                </div>
                <hr className='w-[90%] ml-[5%] mt-10 mb-10' />

                <div className='bg-right p-4'
                    style={{
                        background: ` ${rgba1}`
                    }}
                >
                    <div className='flex flex-col justify-center items-center text-center'>
                        <h1 className='flex flex-col sm:flex-col text-white font-["Be Vietnam Bold"] text-[38px] items-center'>{states.dataLoadHome.secao5.titulo.titulo}
                            <span className='text-white font-["Be Vietnam Bold"] text-[18px] text-center sm:text-[25px] sm:text-justify w-[80%]'>{states.dataLoadHome.secao5.texto.description}</span>
                        </h1>
                        <button onClick={route} style={{
                            backgroundColor: data.corPrimaria,
                        }} className=' text-white font-["Be Vietnam Bold"] text-[20px] sm:w-60 h-[60px] w-[190px] mt-4 rounded-full shadow-md  hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out'>{states.dataLoadHome.secao5.link.link}</button>
                    </div>
                </div>
                {/* FIM SECAO 4 */}

                {/* INICIO SECAO 5 */}
                {
                    states.dataBlog.length > 0 && (
                        <>
                            <hr className='w-[90%] ml-[5%] mt-10 mb-10' />
                            <div className='flex flex-col justify-center items-center text-center p-4'>
                                <div className='flex flex-col justify-center items-center text-center'>
                                    <h1 className=' font-["Be Vietnam Bold"] font-bold text-[38px] sm:text-[48px]'
                                        style={{
                                            textDecorationColor: data.corPrimaria
                                        }}
                                    >Últimas <span
                                        style={{
                                            textDecorationColor: data.corSecundaria
                                        }}
                                    >Notícias</span></h1>
                                    <div className='flex flex-col sm:flex-row gap-x-4 gap-y-4'>
                                        {
                                            states.dataBlog.map((item, index) => {
                                                if (item !== undefined) {
                                                    if (index >= 4) return
                                                    return (
                                                        <Link key={item.id} className='border shadow-md hover:shadow-2xl rounded-md' to={`/blog/${item.id}`}>
                                                            <div className='w-[100%] flex flex-col items-center p-4'>
                                                                <div className='flex flex-row'>
                                                                    <span className='parag break flex  flex-wrap text-[black] font-["Be Vietnam Bold"]  font-bold text-[22px] text-justify pb-4'>{item.titulo}</span>
                                                                </div>
                                                                <p className='parag text-[black] font-["Be Vietnam Bold"] text-[15px] text-justify'>{item.descricao}</p>
                                                            </div>
                                                        </Link>
                                                    )
                                                } else {
                                                    return null
                                                }
                                            })
                                        }
                                    </div>
                                    <Link to='/blog'
                                        style={{
                                            backgroundColor: data.corPrimaria,
                                        }}

                                        className='mt-4 flex items-center justify-center text-white font-["Be Vietnam Bold"] text-[18px] sm:w-60 h-[60px] w-[190px] rounded-full shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-700 active:shadow-lg transition duration-150 ease-in-out'><span>Ler todas as notícias</span></Link>

                                </div>
                            </div>
                            <hr className='w-[90%] ml-[5%] mt-10 mb-10' />
                        </>
                    )
                }
                {/* FIM SECAO 5 */}

                {/* INICIO SECAO 6 */}
                {/* <div className='bg-right'
                    style={{
                        background: `linear-gradient(to top, ${rgba1}, transparent)`
                    }}
                >
                    <div className='flex flex-col justify-center items-center text-center'>
                        <h1 className='text-black font-["Be Vietnam Bold"] font-bold'>
                            {states.dataLoadHome.secao6.titulo.titulo}
                        </h1>
                        <h1 className='text-[#133268] font-["Be Vietnam Bold"] font-bold text-[42px]'>
                            {states.dataLoadHome.secao6.texto.description}
                        </h1>

                        <div id="carouselExampleCaptions" className="carousel slide relative" data-bs-ride="carousel">
                            <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center">
                                {states.dataLoadHome.secao6.img.map((_, index) => (
                                    <button
                                        key={index}
                                        type="button"
                                        data-bs-target="#carouselExampleCaptions"
                                        data-bs-slide-to={index}
                                        className={index === 0 ? "active" : ""}
                                        aria-label={`Slide ${index + 1}`}
                                    ></button>
                                ))}
                            </div>
                            <div className='carrousel flex'>
                                <div
                                    id="carouselDarkVariant"
                                    className="carousel slide carousel-fade carousel-dark relative"
                                    data-bs-ride="carousel"
                                >
                                    {generateBannerBranding(states.dataLoadHome.secao6.img)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* FIM SECAO 6 */}


                <Footer />
            </>
        )

    }

}
