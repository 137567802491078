import { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../../context';
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../../../components/Header/Header/index.jsx";
import Footer from "../../../components/Footer/Foooter/index.jsx";
import Logo from '../../../assets/Logo.png'

import ErrorSite from '../../../components/errorSite'

export default function Login() {

    const history = useNavigate()

    const { states, setters, login, forgotPassword, forgotChangePassword } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [fail, setFail] = useState(false);
    const [forgot, setForgot] = useState(false);
    const [restartPass, setRestartPass] = useState(false);
    const [error, setError] = useState('')

    const { token } = useParams();

    useEffect(() => {
        //setters.setToken(token)
        setRestartPass(true)
    }, [token])

    useEffect(() => {
        token ? setRestartPass(true) : setRestartPass(false)
        setters.setSigned(false)
        localStorage.removeItem('token')
        localStorage.removeItem('email')
    }, [])


    const handdleSubmit = async e => {
        e.preventDefault()
        setLoading(true)
        if (forgot && !restartPass) {
            //TROCAR
            const response = await forgotPassword()
            if (response) {
                setLoading(false)
                setForgot(false)
            } else {
                setLoading(false)
            }
        } else if (!forgot && restartPass) {
            const response = await forgotChangePassword(token)
            if (response) {
                setLoading(false)
                setRestartPass(false)
                history('/login')
            } else {
                setLoading(false)
            }
        } else {
            //Refatorar. Isso esta errado
            const response = await login()
            if (response.status) {
                setLoading(false)
                history('/plataforma')
            } else {
                setError(response.msg)
                setLoading(false)
                setFail(true)
            }
        }
    }


    return (
        <>
            <Header />
            <ErrorSite />
            <div className="w-[90%] pb-16 ">
                <div className="shadow-lg hover:shadow-2xl flex min-h-full sm:mt-[3%] sm:ml-[15%] ml-[12%] mt-[40%] border border-gray-200 rounded-lg">
                    <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                        <div className="mx-auto w-full max-w-sm lg:w-96">
                            <div className="flex items-center justify-center">
                                <img
                                    className="h-auto sm:w-[60%] w-[40%]"
                                    src={Logo}
                                    alt="Your Company"
                                    crossOrigin="anonymous"

                                />
                            </div>

                            <div className="mt-8">
                                <div className="mt-6">
                                    <form onSubmit={handdleSubmit} className="space-y-6">

                                        {
                                            !restartPass && (
                                                <div>
                                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                        E-mail
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autoComplete="email"
                                                            placeholder="Digite seu e-mail"
                                                            value={states.dataLogin.email}
                                                            onChange={e => setters.setDataLogin(items => ({
                                                                ...items,
                                                                email: e.target.value
                                                            }))}
                                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }

                                        {
                                            !forgot && (
                                                <div className="space-y-1">
                                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                                        {restartPass ? 'Nova Senha' : 'Senha'}
                                                    </label>
                                                    <div className="mt-1">
                                                        <input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            value={states.dataLogin.senha}
                                                            onChange={e => setters.setDataLogin(item => ({
                                                                ...item,
                                                                senha: e.target.value
                                                            }))}
                                                            autoComplete="current-password"
                                                            placeholder="Digite sua senha"
                                                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            loading === true && (
                                                <div className="flex justify-center">

                                                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
                                                </div>

                                            )
                                        }
                                        <div>
                                            <button
                                                type="submit"
                                                className="flex w-full justify-center rounded-md border border-transparent bg-blue-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            >
                                                {!forgot && !restartPass && 'Logar'}
                                                {forgot && 'Recuperar Senha'}
                                                {restartPass && 'Cadastrar nova senha'}
                                            </button>
                                        </div>
                                    </form>

                                    {
                                        !restartPass && (
                                            <button
                                                className="mt-3 flex w-full justify-center rounded-md border border-transparent bg-blue-100 py-2 px-4 text-sm font-medium text-blue-500 shadow-sm hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-200
                                                 focus:ring-offset-2"
                                                onClick={() => setForgot(!forgot)}
                                            >
                                                {forgot ? 'Realizar Login' : 'Esqueci minha senha'}
                                            </button>
                                        )
                                    }

                                    {
                                        fail === true && (
                                            <div className="bg-red-100 rounded-lg py-5 px-6 mb-4 mt-6 text-base text-red-700 mb-3" role="alert">
                                                {error}
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="relative hidden flex-1 lg:block">
                        <img
                            className="absolute inset-0 h-full w-full object-cover "
                            src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                            alt=""
                        />
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}