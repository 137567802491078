import Header from '../../../../components/Header/HeaderDash'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../../context'
import HomeSecao1 from '../../../../components/homeSecao6'
import { Link } from 'react-router-dom'
import { Bars3Icon } from '@heroicons/react/24/solid'
import LoadingSpinner from "../../../../components/spinner"


const HomePagina = () => {
    const [data, setData] = useState({})
    const { states, setters, loadDataHomeSecao6, updateSecao6 } = useContext(AuthContext)

    const load = async () => {
        await loadDataHomeSecao6();
    }

    useEffect(() => {
        load();
        setData(states.dataSecao6)
    }, [])

    useEffect(() => {
        setData(states.dataSecao6)
    }, [states.dataSecao6])


    const upload = async (data, id) => {
        await updateSecao6(data, id)
    }


    return (
        <div className='flex flex-col  sm:ml-64'>
            <Header />
            <LoadingSpinner />
            <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex sm:hidden h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setters.setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className='flex flex-col'>
                <div className="mx-auto w-full px-4 py-4 sm:px-6 lg:px-8 border">
                    <h1 className="text-lg font-semibold leading-6 text-gray-900">Configurações Home </h1>
                </div>
                <Link to='/homePagina/'
                    className='mt-4 ml-4 bg-white  hover:bg-gray-200 text-blue-500 font-bold py-2 px-4 rounded w-[80px] border ' >
                    Voltar
                </Link>
                <div className='flex p-6 w-full'>
                    <HomeSecao1 data={states.dataSecao6} upload={upload}
                    />
                </div>
            </div>
        </div>
    )

}


export default HomePagina