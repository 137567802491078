import Header from '../../../components/Header/Header';
import Footer from '../../../components/Footer/Foooter';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { AuthContext } from '../../../context';
import './index.css'
import ErrorSite from '../../../components/errorSite'

export default function Example() {

  const { states, setters, getBlogById } = useContext(AuthContext)
  const history = useNavigate()

  const { id } = useParams();

  const getData = async () => {
    await getBlogById(id)
  }
  useEffect(() => {

    if (id == undefined || id == null || id == '') {
      history('/blogs')
    } else {
      getData()
    }




  }, [id]);




  return (
    <>
      <Header />
      <ErrorSite />
      <div className="overflow-hidden bg-white">
        <div className="relative mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
          <div className="absolute top-0 bottom-0 left-3/4 hidden w-screen bg-gray-50 lg:block" />
          <Link
            to='/blog'
            className="inline-flex items-center mb-8 px-8 py-2 border  text-sm font-medium rounded-full shadow-sm text-black  border-black hover:text-white hover:bg-gray-600"
          >
            Retornar
          </Link>
          <div className="mx-auto max-w-prose text-base lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-8">

            <div className='p-5  rounded-[10px] shadow-sm'>
              <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">{states.blog.titulo}</h3>
              <p className='flex text items-center text-justify mt-5' >
                {states.blog.descricao}
              </p>
            </div>
            <div className="relative   mx-auto max-w-prose text-base lg:max-w-none">
              {
                states.blog.imagem != null && (
                  <figure>
                    <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                      <img
                        className="rounded-lg object-cover object-center shadow-lg"
                        src={states.blog.arquivo}
                        alt="Whitney leaning against a railing on a downtown street"
                        width={1184}
                        height={1376}
                        crossOrigin="anonymous"
                      />
                    </div>
                  </figure>
                )
              }
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}