import { useState, useEffect } from 'react'
import Moment from 'react-moment';
import './table.css'
import { Link } from 'react-router-dom'



const Table = props => {

    const [data, setData] = useState(props.dataLoad)


    const nextFunc = async () => {
        await props.next()
    }

    const previousFunc = async () => {
        await props.previous()
    }




    //Nao depende dos dados
    const postGen = (data) => {
        if (data !== undefined) {
            return (
                <div key={data.id}>
                    <p className="text-sm text-gray-500">
                        <Moment format="DD/MM/YYYY" date={data.data_criacao} />
                    </p>
                    <a href="#" className="mt-2 block">
                        <p className="text-xl font-semibold text-gray-900">{data.titulo}</p>
                        <p className="mt-3 text-base text-gray-500 parag">{data.descricao}</p>
                    </a>
                    <div className="mt-3">

                        <Link to={`/blog/${data.id}`} className="text-base font-semibold"
                            style={{
                                color: `${props.config.corPrimaria}`
                            }}
                        >
                            Leia a hitoria completa
                        </Link>


                    </div>
                </div>
            )

        } else if (data === undefined) {
            const pass = Math.random()
            return (
                <div key={pass}>
                    <p className="text-sm text-gray-500">
                        Sem publicacoes no blog
                    </p>
                </div>
            )

        }
    }



    return (
        <>
            <div className="mt-6 pl-[15%] pb-10
              grid gap-16 pt-10 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">

                {props.dataLoad?.map((item, index) => {
                    if (index >= 4) return
                    return postGen(item)
                })
                }

            </div>
            <nav
                className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                aria-label="Pagination"
            >



                <div className="flex flex-1 justify-between sm:justify-end">
                    <button
                        onClick={previousFunc}
                        className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                    >
                        Previous
                    </button>
                    <button
                        className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                        onClick={nextFunc}
                    >
                        Next
                    </button>
                </div>
            </nav>
        </>
    )
}


export default Table;