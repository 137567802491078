import React from 'react';
import { FaWhatsapp } from 'react-icons/fa'; // Importando ícone do WhatsApp
import './index.css'

const WhatsAppButton = ({ numero }) => {
    const message = encodeURIComponent('Olá! Gostaria de mais informações.');

    return (
        // <div className="fixed   bottom-8 right-32 sm:right-4 flex items-center justify-center">
        <div className="fixed bottom-8 right-4 sm:right-4  flex items-center justify-end">
            <div className="relative group">
                <a href={`https://wa.me/${numero}?text=${message}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center w-12 h-12 text-white bg-green-500 rounded-full shadow-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2"
                    aria-label="Chat via WhatsApp">
                    <FaWhatsapp size={24} />
                </a>
                <span className="absolute hidden px-3 py-1 text-sm text-white bg-green-500 rounded-md shadow-sm group-hover:block -top-10 ml-9 mt-4 -left-36 transform translate-x-1/16">
                    Fale conosco!
                </span>
            </div>
        </div>
    );
};

export default WhatsAppButton;