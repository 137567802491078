import { Fragment, useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/Logo.png'
import {
    FolderIcon,
    HomeIcon,
    XMarkIcon,
    ArchiveBoxIcon,
    ExclamationCircleIcon,
    CogIcon,
    Bars3Icon,
    EnvelopeIcon,
    DocumentTextIcon,
    UserIcon

} from '@heroicons/react/24/outline'

const navigation = [
    { name: 'E-mail recebidos', href: '/emails', icon: EnvelopeIcon, current: false, admin: false, editor: false, user: false },
    { name: 'Blog', href: '/plataformaBlog', icon: DocumentTextIcon, current: false, admin: false, editor: false, user: false },
    { name: 'Configurações', href: '/plataforma', icon: CogIcon, current: false, admin: false, editor: false, user: false },
    { name: 'Página Inicial', href: '/homePagina', icon: HomeIcon, current: true, admin: false, editor: false, user: false },
    { name: 'Página Sobre nós', href: '/sobrePagina', icon: ArchiveBoxIcon, current: true, admin: false, editor: false, user: false },
    { name: 'Página de contato', href: '/contatoPagina', icon: FolderIcon, current: true, admin: false, editor: false, user: false },
    { name: 'Perguntas frequentess', href: '/perguntasFrequentes', icon: Bars3Icon, current: true, admin: false, editor: false, user: false },
    { name: 'Página não encontrada', href: '/naoEncontradaPagina', icon: ExclamationCircleIcon, current: true, admin: false, editor: false, user: false },
    { name: 'Perfil', href: '/perfil', icon: UserIcon, current: true, admin: false, editor: false, user: false }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
    const { states, setters } = useContext(AuthContext)
    const [email, setEmail] = useState('')
    const [role, setRole] = useState(localStorage.getItem('role'))


    useEffect(() => {
        setEmail(localStorage.getItem('email'))
    }, [localStorage.getItem('user')])

    const currentItem = (name) => {
        navigation.map((item) => {
            if (item.href === window.location.pathname) {
                item.current = true
            } else {
                item.current = false
            }
        })

    }

    useEffect(() => {
        currentItem()
    }, [window.location.pathname])


    /*const currentItem = (name) => {
        navigation.map((item) => {
            if (item.name === name) {
                item.current = true
            } else {
                item.current = false
            }
        })

    }*/

    const genNav = (item) => {

        return (<Link
            key={item.name}
            to={item.href}
            onClick={() => currentItem(item.name)}
            className={classNames(
                item.current ? 'bg-blue-900 text-white' : 'text-white-300 hover:bg-blue-700 hover:text-white',
                'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
            )}
        >
            <item.icon
                className={classNames(
                    item.current ? 'text-blue-400' : 'text-blue-800 group-hover:text-blue-500',
                    'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
            />
            {item.name}
        </Link>)

    }

    return (
        <>
            <Transition.Root show={states.sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40 md:hidden" onClose={setters.setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-40 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-[#D1D5DA]">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                                        <button
                                            type="button"
                                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                            onClick={() => setters.setSidebarOpen(false)}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                                    <div className="flex flex-shrink-0 items-center px-4 items-center justify-center">
                                        <img
                                            className="h-8 w-auto"
                                            src={Logo}
                                            crossOrigin='anonymous'
                                            alt="Your Company"
                                        />
                                    </div>
                                    <nav className="mt-5 space-y-1 px-2">
                                        {navigation.map((item) => (
                                            genNav(item)
                                        ))}
                                    </nav>
                                </div>
                                <div className="flex flex-shrink-0 bg-gray-700 p-4">
                                    <a href="#" className="group block flex-shrink-0">
                                        <div className="flex items-center">

                                            <div className="ml-3">
                                                <p className="text-sm font-medium text-white" style={{ wordBreak: 'break-word' }}>{email}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                        <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
                    </div>
                </Dialog>
            </Transition.Root>


            <div className='flex sm:w-full md:w-64'>
                <div className="hidden md:fixed  md:inset-y-0 md:flex md:w-64 md:flex-col">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex min-h-0 flex-1 flex-col bg-[#D1D5DA]">
                        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4 items-center">
                            <div className="flex flex-shrink-0 items-center jutify-centers px-4 ">
                                <img
                                    className="h-16 w-auto"
                                    src={Logo}
                                    crossOrigin='anonymous'
                                    alt="Sinprev"
                                />
                            </div>
                            <hr className='border-gray-900/10 mt-5 w-full' />
                            <nav className="mt-5 flex-1 space-y-1 px-2">
                                {navigation.map((item) =>
                                    genNav(item)
                                )}
                            </nav>
                        </div>
                        <div className="flex flex-shrink-0 bg-blue-700 p-4">
                            <a href="#" className="group block w-full flex-shrink-0">
                                <div className="flex items-center">

                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-white" style={{ wordBreak: 'break-word' }}>{email}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}