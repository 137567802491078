import { Fragment } from 'react'
import { Disclosure } from '@headlessui/react'
import { AiOutlineMail, AiOutlineWhatsApp } from 'react-icons/ai'
import Modal from '../../Modal'

import {
    useContext, useState, useEffect
} from 'react'
import { AuthContext } from '../../../context'



export default function Example() {

    const { states, setters } = useContext(AuthContext)

    const [data, setData] = useState({})
    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config'))
        setData(config)

    }, [])


    const [hover, setHover] = useState(false);

    const hoverStyle = {
        backgroundColor: hover ? data.corSecundaria : data.corPrimaria, // Substitua 'corOriginal' pela cor inicial
    };
    const [hover1, setHover1] = useState(false);

    const hoverStyle1 = {
        backgroundColor: hover1 ? data.corSecundaria : data.corPrimaria, // Substitua 'corOriginal' pela cor inicial
    };
    if (Object.keys(data).length > 0) {




        return (



            <>
                <Modal isOpen={states.modalContact} className='flex flex-col transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-[40%] sm:p-6"'>
                    <div className='flex flex-col w-[100%] items-center justify-center '>
                        <h1 className='text-2xl font-bold text-gray-800'>Escolha uma das opções de contato</h1>
                        <p className='text-gray-600 text-sm'>Entre em contato com a gente</p>
                        <hr className='bg-black w-[100%] mt-2 mb-2' />
                    </div>

                    <div className='hover:shadow-2xl  rounded-[10px]'>
                        <div className='flex flex-col w-[100%] items-center justify-center mt-4 '>
                            <div className='flex  '>
                                <span className="inline-flex h-14 w-14 items-center justify-center rounded-full bg-gray-500  mr-16">
                                    <span className="text-xl font-medium leading-none text-white">{`${data?.nome[0]}${data?.nome[1]}`}</span>
                                </span>
                                <div className='flex flex-col items-center justify-center ml-4 mr-16'>
                                    <h1 className='text-lg font-bold text-gray-800'>{data?.nome}</h1>
                                    <p className='text-gray-600 text-sm'>Entre em contato!</p>
                                </div>
                                <div className='flex ml-8 items-center'>
                                    <a href={`https://api.whatsapp.com/send?phone=${data?.whatsapp}&text=Ola!%20`} className='flex mr-5 items-center justify-center w-10 h-10 rounded-full bg-green-500 hover:bg-green-600'>
                                        <AiOutlineWhatsApp className='text-white' size={25} />
                                    </a>
                                    <a href={`mailto:${data.email}`} className='flex items-center justify-center w-10 h-10 rounded-full bg-red-500 hover:bg-red-600'>
                                        <AiOutlineMail className='text-white' size={25} />
                                    </a>
                                </div>
                            </div>
                            <hr className='bg-black w-[100%] mt-2' />
                        </div>
                    </div>


                    <div>
                        <button
                            type="button"
                            className="mt-4 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-800 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
                            onClick={() => setters.setModalContact(false)}
                        >
                            Fechar
                        </button>


                    </div>




                </Modal>
                <Disclosure as="nav" className="hidden lg:block "
                    style={{ backgroundColor: data?.corPrimaria }}
                >
                    <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 ">
                        <div className="relative flex h-10 items-center pr-6">

                            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start pr-[6%] mr-[5%]">
                                <div className="hidden sm:ml-6 sm:block">
                                    <div className="flex space-x-4">
                                        <a
                                            onClick={() => setters.setModalContact(true)}
                                            style={hoverStyle}
                                            onMouseEnter={() => setHover(true)}
                                            onMouseLeave={() => setHover(false)}
                                            className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                                        >
                                            <div className='flex flex-col justify-center items-center '>
                                                <span className='mr-3 text-[15px] flex flex-row justify-center items-center'><AiOutlineMail className='text-white mr-2' />  Entre em contato por e-mail</span>

                                            </div>
                                        </a>
                                        <a
                                            style={hoverStyle1}
                                            onMouseEnter={() => setHover1(true)}
                                            onMouseLeave={() => setHover1(false)}
                                            onClick={() => setters.setModalContact(true)}

                                            className='text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium'
                                        >
                                            <div className='flex flex-col justify-center items-center '>
                                                <span className='mr-3 text-[15px] flex flex-row justify-center items-center'><AiOutlineWhatsApp className='text-white mr-2' />  Entre em contato por WhatsApp</span>

                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Disclosure>
            </>
        )

    }
}
