import { useContext } from 'react'
import { AuthContext } from "../../context"
import Logo from '../../assets/Logo.png'

export default function SpinnerOverlay() {
    const { states } = useContext(AuthContext)
    if (states.errorConfig == true) {
        return (
            <div
                // className='flex flex-1 flex-col md:pl-64'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'white',
                    zIndex: 9999,
                }}
            >
                {/* Card para site bloqueado  e/ou fora do ar */}
                <div className='flex w-full border justify-center h-screen items-center'>
                    <div className='flex flex-col justify-center items-center border  p-5 rounded-[15px] shadow-md'>
                        <img src={Logo} className='w-1/2' />
                        <h1 className='text-3xl font-bold text-center'>Site bloqueado e/ou fora do ar</h1>
                        <p className='text-center'>Entre em contato com o administrador do sistema</p>
                    </div>
                </div>

            </div>
        )
    }
}