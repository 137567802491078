import { Routes, Route } from 'react-router-dom';
import RouterWrapper from './Route.jsx';
import Home from '../views/site/Home';
import Sobre from '../views/site/Sobre';
import FAQ from '../views/site/FAQ';
import Contato from '../views/site/Contato';
import NotFound from '../views/notFound.jsx';
import Blog from '../views/site/Blogs';
import BlogContent from '../views/site/Blog';


import Login from '../views/plataforma/login';
import HomePlataforma from '../views/plataforma/home';
import HomePagina from '../views/plataforma/homePagina';
import SobrePagina from '../views/plataforma/Sobre';
import ContatoPagina from '../views/plataforma/Contato';
import PerguntasFrequentes from '../views/plataforma/PerguntasFrequentes';
import NaoEncontrada from '../views/plataforma/NaoEncontrada';
import Emails from '../views/plataforma/Emails';
import PlataformaBlog from '../views/plataforma/Blog';
import Perfil from '../views/plataforma/Perfil';
import ConfirmMail from '../views/plataforma/ConfirmMail'
import AuthMail from '../views/plataforma/AuthMail'

import Secao1 from '../views/plataforma/homePagina/secao1';
import Secao2 from '../views/plataforma/homePagina/secao2';
import Secao3 from '../views/plataforma/homePagina/secao3';
import Secao4 from '../views/plataforma/homePagina/secao4';
import Secao5 from '../views/plataforma/homePagina/secao5';
import Secao6 from '../views/plataforma/homePagina/secao6';



const FuncNotFound = () => {
    return (
        <NotFound />
    )
}

const RouterHomePagina = () => {
    return (
        <Routes>
            <Route path="/" element={<RouterWrapper isPrivate={true} component={HomePagina} />} />
            <Route path="/secao1" element={<RouterWrapper isPrivate={true} component={Secao1} />} />
            <Route path="/secao2" element={<RouterWrapper isPrivate={true} component={Secao2} />} />
            <Route path="/secao3" element={<RouterWrapper isPrivate={true} component={Secao3} />} />
            <Route path="/secao4" element={<RouterWrapper isPrivate={true} component={Secao4} />} />
            <Route path="/secao5" element={<RouterWrapper isPrivate={true} component={Secao5} />} />
            <Route path="/secao6" element={<RouterWrapper isPrivate={true} component={Secao6} />} />
        </Routes>
    )
}


export default function Router() {
    return (
        <Routes>
            <Route path="/login/:token?" element={<RouterWrapper component={Login} />} />
            <Route path="/emails" element={<RouterWrapper isPrivate={true} component={Emails} />} />
            <Route path="/plataforma" element={<RouterWrapper isPrivate={true} component={HomePlataforma} />} />
            <Route path="/homePagina/*" element={<RouterWrapper isPrivate={true} component={RouterHomePagina} />} />
            <Route path="/sobrePagina" element={<RouterWrapper isPrivate={true} component={SobrePagina} />} />
            <Route path="/contatoPagina" element={<RouterWrapper isPrivate={true} component={ContatoPagina} />} />
            <Route path="/plataformaBlog" element={<RouterWrapper isPrivate={true} component={PlataformaBlog} />} />
            <Route path="/perguntasFrequentes" element={<RouterWrapper isPrivate={true} component={PerguntasFrequentes} />} />
            <Route path="/naoEncontradaPagina" element={<RouterWrapper isPrivate={true} component={NaoEncontrada} />} />
            <Route path="/perfil" element={<RouterWrapper isPrivate={true} component={Perfil} />} />

            <Route path="/changeMail/:token"  element={<RouterWrapper component={ConfirmMail} />} />
            <Route path="/authMail/:token"  element={<RouterWrapper component={AuthMail} />} />
            <Route path="/" element={<RouterWrapper component={Home} />} />
            <Route path="/sobre" element={<RouterWrapper component={Sobre} />} />
            <Route path="/blog" element={<RouterWrapper component={Blog} />} />
            <Route path="/blog/:id" element={<RouterWrapper component={BlogContent} />} />
            <Route path="/faq" element={<RouterWrapper component={FAQ} />} />
            <Route path="/contato" element={<RouterWrapper component={Contato} />} />
            <Route path='*' element={<FuncNotFound />} />
        </Routes>
    )
}