


import { XMarkIcon } from '@heroicons/react/24/solid'
import { useState, useEffect, useCallback } from 'react';


const Config = ({ data, upload }) => {

    const [objHome, setObjHome] = useState({})

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setObjHome(data)
        }
    }, [data])

    // // console.log(data.secao3?.numbers[0]?.texto.description)

    /* LOGICA IMPLEMENTADA SECAO 6 */
    const [originalImages, setOriginalImages] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [removedImages, setRemovedImages] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);
    const [idSecao, setIdSecao] = useState('');
    const [error, setError] = useState(false);

    const onDragEnter = () => setIsDragOver(true);
    const onDragLeave = () => setIsDragOver(false);
    const isValidHex = (hex) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);


    // Carregar imagens iniciais do banco de dados
    useEffect(() => {
        if (data) {
            setOriginalImages([data]);
            setNewImages([]);
            setRemovedImages([]);
            setIdSecao(data.id)
        }
    }, [data]);


    const imageSpaces = () => {
        const allImages = [...originalImages, ...newImages];
        const placeholders = 1 - allImages.length; // Calcula quantos placeholders são necessários

        return (
            <>
                {allImages.map((image, index) => (
                    <div key={index} className="relative">
                        <img
                            src={image instanceof File ? URL.createObjectURL(image) : image.url}
                            alt={`Preview ${index}`}
                            className="h-96 w-full rounded-md object-contain"
                            crossOrigin='anonymous'
                        />
                        <XMarkIcon
                            onClick={() => handleRemoveFile(image, index)}
                            className="absolute top-0 right-0 h-5 w-5 text-red-500 cursor-pointer"
                            aria-hidden="true"
                        />
                    </div>
                ))}
                {Array.from({ length: placeholders }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="h-96 w-full rounded-md object-contain border-2 border-dashed border-gray-300 flex justify-center items-center">
                        {/* Aqui você pode colocar um ícone ou texto indicando que é um placeholder */}
                        <p className="text-gray-500">Adicionar Imagem</p>
                    </div>
                ))}
            </>
        );
    };


    const onDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation(); //

        // Extrair arquivos do evento de drop
        const droppedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;

        // Calcular o número total de imagens após adicionar as novas
        const totalImages = originalImages.length + newImages.length + droppedFiles.length;

        if (totalImages > 1) {
            setError('Pode ter no máximo 1 arquivo adicionado.');
        } else {
            // Adicionar novos arquivos à lista de novas imagens
            setNewImages([...newImages, ...Array.from(droppedFiles)]);
        }
    }, [originalImages, newImages]);





    const onDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation(); //
    };

    // // console.log(data.secao3?.numbers[0]?.texto.description)

    // Estados para imagens originais, novas e removidas


    // Atualizar a lista de novas imagens
    const onFileChange = (event) => {
        const droppedFiles = event.target.files;
        const totalImages = originalImages.length + newImages.length + droppedFiles.length;

        if (totalImages > 1) {
            setError('Pode ter no máximo 1 arquivo adicionado.');
        } else {
            // Adicionar novos arquivos à lista de novas imagens
            setNewImages([...newImages, ...Array.from(droppedFiles)]);
        }
    };

    // Remover uma imagem
    const handleRemoveFile = (image, index) => {
        if (originalImages.includes(image)) {
            if (removedImages.length == 0) {
                setRemovedImages([image]);
            } else {
                setRemovedImages([...removedImages, image]);
            }
            setOriginalImages(originalImages.filter((_, idx) => idx !== index));
        } else {
            setNewImages(newImages.filter((_, idx) => idx !== index));
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        //AQUI DEVE PERMITIR CASO SEJA SOMENTE TEXTO
        // if (newImages.length == 0 && removedImages.length == 0) {
        //     setError('Não ocorreu nenhuma alteração nas imagens')
        //     return
        // }
        if ((newImages.length + originalImages.length) > 1) {
            setError('Só pode ter no máximo 1 imagem')
            return

        }
        if ((newImages.length + originalImages.length) < 1) {
            setError('Deve ter 1 imagem')
            return

        }
        //DEVE TER EXATAMENTE 4 SELECIONADOS -> SEMPRE 
        const updatedImages = {
            id: idSecao,
            image: objHome.imagem,
            newImages: newImages,
        };

        const formData = new FormData();
        // formData.append('id', idSecao)
        newImages.forEach((file) => {
            formData.append('imagem', file); // 'file' é um objeto File
        });
        formData.append('image', objHome.imagem)



        await upload(formData, idSecao)

    };
    /* FIM DA LOGICA IMPLEMENTADA NA SECAO 6 */



    return (
        <form className='flex w-full flex-col' onSubmit={onSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    {/* SECAO 01 */}


                    {/* SECAO 02 */}
                    <hr className="mt-6 border-gray-200 border-1" aria-hidden="true" />
                    <div>

                        <div className="mt-6">
                            <h2>
                                <label htmlFor="about" className="block text-sm font-medium text-gray-900">
                                    Seção 02 - Imagem e breve apresentação
                                </label>
                            </h2>
                            <div className="mt-2 flex justify-center w-[100%]">
                                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                    <div className={`text-center ${isDragOver ? 'border-indigo-600' : 'border-gray-900/25'} mt-2 flex justify-center w-full rounded-lg border border-dashed px-6 py-10`}
                                        onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter}
                                        onDragLeave={onDragLeave}>
                                        <div className="mt-4 flex text-sm text-center leading-6 text-gray-600">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer  rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Upload a file</span>
                                                <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={onFileChange} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG up to 10MB</p>

                                    </div>
                                </div>
                                <div className="mt-2 grid grid-cols-1 gap-1 ml-6 w-[30%] ">
                                    {imageSpaces()}
                                </div>

                            </div>

                            {
                                error != '' && <p className="text-xs leading-5 text-red-600">{error}</p>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    {
                        Object.keys(data).length > 0 ? 'Atualizar' : 'Salvar'
                    }
                </button>
            </div>
        </form >
    )


}



export default Config;
