import { useContext } from 'react'
import { AuthContext } from "../../context"

export default function SpinnerOverlay() {
    const { states } = useContext(AuthContext)
    if (states.loading == true) {
        return (
            <div
                // className='flex flex-1 flex-col md:pl-64'
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9999,
                }}
            >
                <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-24 w-24 border-t-4 border-blue-500 border-solid"></div>
                </div>

            </div>
        )
    }
}