import './blogs.css'
import { useEffect, useContext, useState } from "react";
import { Link } from 'react-router-dom';
import Footer from "../../../components/Footer/Foooter";
import Header from "../../../components/Header/Header";
import { AuthContext } from "../../../context";
import BlogGrid from "../../../components/Grid/BlogGrid";
import ErrorSite from '../../../components/errorSite'



const Blogs = () => {



    const { states, setters, getWithLimitBlog } = useContext(AuthContext)
    const [config, setConfig] = useState({})


    const data = async () => {
        await getWithLimitBlog()
    }

    useEffect(() => {
        data()
        setConfig(JSON.parse(localStorage.getItem('config')) || '')
    }, [])

    const next = async () => {
        // console.log('asdasd')
        if (states.limiteBuscaDataBlog) {
            return
        } else {
            setters.setOffsetDataBlog(states.offsetDataBlog + states.limitDataBlog)
        }


    };


    const previous = async () => {
        if (states.offsetDataBlog <= 0) {
            states.offsetDataBlog < 0 && setters.setOffsetDataBlog(0)
            return
        } else {
            if (states.limiteBuscaDataBlog) setters.setLimiteBuscaDataBlog(false)
            setters.setOffsetDataBlog(states.offsetDataBlog - states.limitDataBlog)
        }

    };

    useEffect(() => {
        if (states.dataBlog.length > 0) {
            data()
        }
        //Isso aqui atrapalha a paginacao
    }, [states.offsetDataBlog])

    const [rgba1, setRgba1] = useState('')
    const [rgba2, setRgba2] = useState('')

    const hexToRGBA = (hex, alpha) => {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config'))
        setRgba1(hexToRGBA(config.corPrimaria, 0.5))
        setRgba2(hexToRGBA(config.corSecundaria, 0.5))

    }, [])

    return (
        <>
            <Header />
            <div className="flex flex-col justify-center items-center p-20" style={{
                height: '100px', // Ajuste a altura conforme necessário
                background: `${rgba1}`
            }}>
                <h1 className="flex flex-col items-center justify-center font-['Be Vietnam Bold'] font-bold text-white md:text-[50px] text-[30px]">Blog</h1>
                <div className="flex flex-row bg">
                    <Link to="/" className="text-gray-200">Página inicial</Link>
                    <span className="text-white"> &rsaquo; Blog </span>
                </div>
            </div>
            <ErrorSite />
            <div className="relative mx-auto max-w-lg  lg:max-w-7xl p-6">
                <div>
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Blog</h2>
                    <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:items-center lg:gap-5">
                        <p className="text-xl text-gray-500 mt-5">Aqui você encontra todas as postagens!</p>
                    </div>
                </div>
                <hr className="my-6 border-2 border-gray-200" aria-hidden="true" />
                {
                    states.dataBlog.length === 0 ? <>
                        <div>
                            <p className="text-xl text-gray-500 mt-5">Nenhuma postagem encontrada!</p>
                        </div>
                    </> :
                        <BlogGrid states={states} setters={setters} dataLoad={states.dataBlog} config={config} next={next} previous={previous}
                        />
                }
            </div>
            <div className="flex flex-col justify-center items-center p-20 h-[220px] sm:h-[100px] text-center" style={{
                // Ajuste a altura conforme necessário
                background: `${rgba1}`
            }}>
                <h1 className="flex flex-col items-center justify-center font-['Be Vietnam Bold'] font-bold text-white md:text-[50px] text-[30px]">Entre em contato conosco</h1>
                <Link to="/contato" type="button" style={{
                    background: `${rgba2}`
                }} className="flex flex-col justify-center inline-block sm:w-60 h-[60px] w-[190px] mt-4 px-2 py-2.5 text-white text-center font-medium text-xs leading-tight uppercase rounded-full shadow-md  hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"><span className="text-[12px]">Entrar em contato</span></Link>
            </div>
            <Footer />
        </>
    );

}


export default Blogs;