import { useState, useContext } from "react";
import { AuthContext } from "../../context";

const escurecerCor = (rgba, percent) => {
    // Extrai os valores de R, G, B e A da string RGBA
    var [r, g, b, a] = rgba.match(/\d+\.?\d*/g).map(Number);

    // Calcula a nova cor
    r = Math.floor(r * (1 - percent));
    g = Math.floor(g * (1 - percent));
    b = Math.floor(b * (1 - percent));

    // Garante que os valores não sejam menores que 0
    r = Math.max(r, 0);
    g = Math.max(g, 0);
    b = Math.max(b, 0);

    // Retorna a nova cor em formato RGBA
    return `rgba(${r}, ${g}, ${b}, ${a})`;
}


const Contato = ({ corPrimaria, url }) => {
    const { states, setters, enviarEmailCliente } = useContext(AuthContext)

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [telefone, setTelefone] = useState('')
    const [mensagem, setMensagem] = useState('')
    const [title, setTitle] = useState('')


    const formatTelefone = (telefone) => {
        //Com ddd 
        const ddd = telefone?.substring(0, 2)
        const parte1 = telefone?.substring(2, 7)
        const parte2 = telefone?.substring(7, 11)
        return `(${ddd}) ${parte1}-${parte2}`

    }

    const send = async (e) => {
        e.preventDefault()
        //Apagar os inputs aqui
        //send({ nome, email, telefone, mensagem, title})
        const sendResponse = await enviarEmailCliente({ nome, email, telefone, mensagem, title })
        if (sendResponse == true || sendResponse == false) {
            setNome('')
            setEmail('')
            setTelefone('')
            setMensagem('')
            setTitle('')

        }
    }


    if (corPrimaria) {

        const [corFundo, setCorFundo] = useState(corPrimaria)
        const [corEscura, setCorEscura] = useState(escurecerCor(corPrimaria, 0.5))

        return (
            <div className="relative bg-white">
                <div className="lg:absolute lg:inset-0 lg:left-1/2">
                    <img
                        className="h-64 w-full bg-gray-50 object-cover sm:h-80 lg:absolute lg:h-full"
                        src={url}
                        crossOrigin="anonymous"
                    />
                </div>
                <div className="pb-24 pt-16 sm:pb-32 sm:pt-24 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:pt-32">
                    <div className="px-6 lg:px-8">
                        <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                            {
                                states.send && (
                                    <>
                                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Já recebemos sua mensagem!</h2>
                                        <p className="mt-2 text-lg leading-8 text-gray-600">
                                            Em breve entraremos em contato!
                                        </p>
                                    </>

                                )
                            }
                            {
                                !states.send && (
                                    <>
                                        <h2 className="text-3xl font-bold tracking-tight text-gray-900">Fale com a nossa equipe!</h2>
                                        <p className="mt-2 text-lg leading-8 text-gray-600">
                                            Estamos prontos para te ajudar, entre em contato conosco e tire suas dúvidas.
                                        </p>
                                        <form action="#" method="POST" className="mt-16" onSubmit={send}>
                                            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                                                <div className="sm:col-span-2">
                                                    <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Seu nome
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            value={nome}
                                                            onChange={(e) => setNome(e.target.value)}
                                                            required
                                                            type="text"
                                                            name="first-name"
                                                            id="first-name"
                                                            autoComplete="given-name"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="sm:col-span-2">
                                                    <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                                                        Seu e-mail
                                                    </label>
                                                    <div className="mt-2.5">
                                                        <input
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            required
                                                            id="email"
                                                            name="email"
                                                            type="email"
                                                            autoComplete="email"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <div className="flex justify-between text-sm leading-6">
                                                        <label htmlFor="phone" className="block font-semibold text-gray-900">
                                                            Seu telefone
                                                        </label>
                                                    </div>
                                                    <div className="mt-2.5">
                                                        <input
                                                            value={telefone}
                                                            onChange={(e) => {
                                                                const onlyNumber = e.target.value.replace(/\D/g, '')
                                                                const format = formatTelefone(onlyNumber)
                                                                setTelefone(format)
                                                            }
                                                            }
                                                            required
                                                            type="tel"
                                                            name="phone"
                                                            id="phone"
                                                            autoComplete="tel"
                                                            aria-describedby="phone-description"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <div className="flex justify-between text-sm leading-6">
                                                        <label htmlFor="phone" className="block font-semibold text-gray-900">
                                                            Digite o Assunto
                                                        </label>
                                                    </div>
                                                    <div className="mt-2.5">
                                                        <input
                                                            value={title}
                                                            onChange={(e) => setTitle(e.target.value)}
                                                            type="text"
                                                            name="phone"
                                                            id="phone"
                                                            autoComplete="tel"
                                                            aria-describedby="phone-description"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="sm:col-span-2">
                                                    <div className="flex justify-between text-sm leading-6">
                                                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                                                            Escreva sua mensagem
                                                        </label>
                                                        <p id="message-description" className="text-gray-400">
                                                            Máximo de 500 caracteres
                                                        </p>
                                                    </div>
                                                    <div className="mt-2.5">
                                                        <textarea
                                                            value={mensagem}
                                                            onChange={(e) => setMensagem(e.target.value)}
                                                            id="message"
                                                            name="message"
                                                            rows={4}
                                                            aria-describedby="message-description"
                                                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            defaultValue={''}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-10 flex justify-end border-t border-gray-900/10 pt-8">
                                                <button
                                                    type="submit"
                                                    className="rounded-md  px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                                                    style={{ backgroundColor: corFundo }}
                                                    onMouseEnter={() => {
                                                        setCorFundo(corEscura)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setCorFundo(corPrimaria)
                                                    }}
                                                >
                                                    Enviar mensagem
                                                </button>
                                            </div>
                                        </form>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}






export default Contato;