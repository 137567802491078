import { AuthContext } from "../../../context"
import { useContext, useEffect, useState } from 'react'
import { CheckCircleIcon, ChevronDoubleUpIcon } from '@heroicons/react/20/solid'
import Header from '../../../components/Header/HeaderDash'
import { toast } from "react-toastify"
import LoadingSpinner from "../../../components/spinner"

export default function Perfil() {
    const { states, setters, loadPerfil, editPerfil, trocarSenha, trocarEmail } = useContext(AuthContext)
    // const { changeEmail, changePassword, baterPonto, dataPontosUsuario } = useContext(AuthContextApi)

    const [senha, setSenha] = useState('')
    const [novaSenha, setNovaSenha] = useState('')
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState('')

    const [hasNovaSenha, setHasNovaSenha] = useState(false)
    const [errorSenha, setErrorSenha] = useState(false)
    const [errorNovaSenha, setErrorNovaSenha] = useState(false)

    const [cumprimento, setCumprimento] = useState('')

    const [arrowButton, setArrowButton] = useState(true)
    const [arrowButtonPerfil, setArrowButtonPerfil] = useState(true)

    const [oldMail, setOldMail] = useState(states.email)

    const [email, setEmail] = useState(states.perfil.email)
    const [nome, setNome] = useState(states.perfil.nome)
    const [cep, setCep] = useState(states.perfil.cep)
    const [endereco, setEndereco] = useState(states.perfil.endereco)
    const [cidade, setCidade] = useState(states.perfil.cidade)
    const [estado, setEstado] = useState(states.perfil.estado)
    const [telefone, setTelefone] = useState(states.perfil.telefone)
    const [cpf_cnpj, setCpf_cnpj] = useState(states.perfil.cpf_cnpj)
    const [numeroResidencia, setNumeroResidencia] = useState('')

    const [errorEmail, setErrorEmail] = useState(false)
    const [errorNome, setErrorNome] = useState(false)
    const [errorCep, setErrorCep] = useState(false)
    const [errorEndereco, setErrorEndereco] = useState(false)
    const [errorCidade, setErrorCidade] = useState(false)
    const [errorEstado, setErrorEstado] = useState(false)
    const [errorTelefone, setErrorTelefone] = useState(false)
    const [errorCpf_cnpj, setErrorCpf_cnpj] = useState(false)




    const formatarCpfCnpj = (value) => {
        if (value?.length <= 11) {
            return value?.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        }
        else {
            return value?.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
        }
    }

    const formatarTelefone = (value) => {
        return value?.replace(/\D/g, '').replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
    }

    const formatarCep = (value) => {
        return value?.replace(/\D/g, '').replace(/(\d{5})(\d{3})/, '$1-$2')
    }


    const load = async () => {
        await loadPerfil();

    }


    useEffect(() => {
        load()
    }, []);


    useEffect(() => {
        const dataHora = new Date()
        const hora = dataHora.getHours()
        if (hora >= 1 && hora <= 11) {
            setCumprimento('Bom dia')
        }
        else if (hora >= 12 && hora <= 17) {
            setCumprimento('Boa tarde')
        }
        else if (hora >= 18 && hora <= 23) {
            setCumprimento('Boa noite')
        }
        else {
            setCumprimento('Olá')
        }
        setCep(formatarCep(states.perfil.cep))
        setTelefone(formatarTelefone(states.perfil.telefone))
        setCpf_cnpj(formatarCpfCnpj(states.perfil.cpf_cnpj))
        setNumeroResidencia(e => {
            const regexNumber = /\d+/g;
            const numero = states.perfil?.endereco?.match(regexNumber)
            return numero ? numero[0] : 0
        })
        setNome(states.perfil.nome)
        setCidade(states.perfil.cidade)
        setEstado(states.perfil.estado)
        setEndereco(states.perfil.endereco)
        setEmail(states.perfil.email)

    }, [states.perfil]);

    useEffect(() => {
        if (novaSenha == '') {
            setConfirmarNovaSenha('')
            setHasNovaSenha(false)
        }
        else {
            setHasNovaSenha(true)
        }
    }, [novaSenha]);

    const confereSenha = () => {
        if (errorSenha) {
            if (senha == '') {
                setErrorSenha(true)
            }
            else {
                setErrorSenha(false)
            }
        }

    }

    const confereNovaSenha = () => {
        if (novaSenha == confirmarNovaSenha) {
            setErrorNovaSenha(false)
        }
        else {
            setErrorNovaSenha(true)
        }
    }

    const enviarFormulario = async (e) => {
        e.preventDefault()
        if (email != oldMail) {
            await mudarEmail()
        }
        if (senha != '' && novaSenha != '') {
            await mudarSenha()
        }

    }

    const enviarFormPerfil = async (e) => {
        e.preventDefault()
        if (nome == '') {
            setErrorNome(true)
        } else {
            setErrorNome(false)

        }
        if (cep == '') {
            setErrorCep(true)
        } else {
            setErrorCep(false)
        }
        if (cidade == '') {
            setErrorCidade(true)
        }
        else {
            setErrorCidade(false)
        }
        if (estado == '') {
            setErrorEstado(true)
        }
        else {
            setErrorEstado(false)
        }
        if (telefone == '') {
            setErrorTelefone(true)
        }
        else {
            setErrorTelefone(false)
        }
        if (cpf_cnpj == '') {
            setErrorCpf_cnpj(true)
        }
        else {
            setErrorCpf_cnpj(false)
        }
        if (errorNome == false && errorCep == false && errorEndereco == false && errorCidade == false && errorEstado == false && errorTelefone == false && errorCpf_cnpj == false) {
            const objPerfil = {
                nome: nome,
                cep: cep,
                cidade: cidade,
                numeroResidencia: numeroResidencia,
                estado: estado,
                telefone: telefone,
                cpf_cnpj: cpf_cnpj
            }
            await editPerfil(objPerfil)
            setArrowButtonPerfil(true)

        }
    }

    const mudarEmail = async () => {
        if ((email == '' || email == null) && senha == '') {
            setErrorEmail(true)
        } else {
            const objEmail = { email: email,  senha }
            await trocarEmail(objEmail)
        }

    }

    const mudarSenha = async () => {
        if (senha == '') {
            setErrorSenha(true)
        }
        else {
            if (errorNovaSenha == false) {
                const objPassword = { senha, novaSenha }
                await trocarSenha(objPassword)
                setSenha('')
                setArrowButton(true)

            }
        }
    }


    const openForm = async () => {
        if (arrowButton == true) {
            setArrowButton(false)
        } else {
            setArrowButton(true)
        }
    }

    const openFormPerfil = async () => {
        if (arrowButtonPerfil == true) {
            setArrowButtonPerfil(false)
        } else {
            setArrowButtonPerfil(true)
        }
    }

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                {/* <Navbar />
                <SpinnerOverlay /> */}
                <div className="flex flex-1 flex-col md:pl-64 mb-4 ">
                    <Header />
                    <LoadingSpinner />
                    <div className="p-4" >
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="py-2 flow-root mb-4">
                                <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                            <div className='p-5'>
                                                <div className="space-y-12 sm:space-y-16">
                                                    <div>
                                                        <div className="rounded-md flex justify-between items-center bg-white">
                                                            <div className="flex flex-1 justify-between items-center">
                                                                <div className="flex flex-col">
                                                                    <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9 flex">
                                                                        {cumprimento}, {localStorage.getItem('email')}<CheckCircleIcon
                                                                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                Object.keys(states.perfil).length > 0 && (
                                    <>
                                        <div className="py-2 mt-4 flow-root">
                                            <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                                        <div className='p-5' >
                                                            <div className="space-y-12 sm:space-y-16">
                                                                <div>
                                                                    <div className="rounded-md flex justify-between items-center bg-white">
                                                                        <div className="flex flex-1 justify-between items-center">
                                                                            <div className="flex flex-col">
                                                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do usuário</h2>
                                                                                <div className="flex items-center">
                                                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                                                                        Aqui você pode alterar as informações do seu usuário.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex-1 flex bg-white justify-end items-center'>
                                                                            <button
                                                                                className="flex items-center text-gray-600 hover:text-gray-800"
                                                                                onClick={openForm}
                                                                            >
                                                                                <ChevronDoubleUpIcon
                                                                                    className={`${arrowButton ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    {!arrowButton && arrowButtonPerfil &&
                                                                        <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Email
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="email"
                                                                                        autoComplete="email"
                                                                                        value={email}
                                                                                        onChange={e => setEmail(e.target.value)}
                                                                                        className="block w-full p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorEmail === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o email e senha.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Senha atual
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="password"
                                                                                        name="password"
                                                                                        type="password"
                                                                                        autoComplete="password"
                                                                                        placeholder='Digite a senha atual ..'
                                                                                        value={senha}
                                                                                        onChange={e => setSenha(e.target.value)}
                                                                                        onBlur={() => confereSenha()}
                                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    errorSenha === true && (
                                                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                            É necessário preencher a senha para efetuar edições.
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Nova senha
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="newPassword"
                                                                                        name="newPassword"
                                                                                        type="password"
                                                                                        autoComplete="newPassword"
                                                                                        placeholder='Digite a nova senha ..'
                                                                                        value={novaSenha}
                                                                                        onChange={e => setNovaSenha(e.target.value)}
                                                                                        onBlur={() => confereNovaSenha()}
                                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                </div>
                                                                                {
                                                                                    errorNovaSenha === true && (
                                                                                        <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                            A Senha e a Nova senha não conferem.
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            </div>

                                                                            {hasNovaSenha &&
                                                                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                        Confirme a nova senha
                                                                                    </label>
                                                                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                        <input
                                                                                            id="newPassword"
                                                                                            name="newPassword"
                                                                                            type="password"
                                                                                            autoComplete="newPassword"
                                                                                            placeholder='Digite a confirmação ..'
                                                                                            value={confirmarNovaSenha}
                                                                                            onChange={e => setConfirmarNovaSenha(e.target.value)}
                                                                                            onBlur={() => confereNovaSenha()}
                                                                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                        />
                                                                                    </div>
                                                                                    {
                                                                                        errorNovaSenha === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                A Senha e a Nova senha não conferem.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>}

                                                                </div>
                                                            </div>

                                                            {!arrowButton &&
                                                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                                                    <button
                                                                        onClick={enviarFormulario}
                                                                        className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="py-2 mt-4 flow-root">
                                            <div className="-my-4 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                    <div className="overflow-hidden border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                                        <div className='p-5' >
                                                            <div className="space-y-12 sm:space-y-16">
                                                                <div>
                                                                    <div className="rounded-md flex justify-between items-center bg-white">
                                                                        <div className="flex flex-1 justify-between items-center">
                                                                            <div className="flex flex-col">
                                                                                <h2 className="text-base font-semibold leading-7 text-gray-900">Informações do perfil</h2>
                                                                                <div className="flex items-center">
                                                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                                                                        Aqui você pode alterar as informações do seu perfil.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='flex-1 flex bg-white justify-end items-center'>
                                                                            <button
                                                                                className="flex items-center text-gray-600 hover:text-gray-800"
                                                                                onClick={openFormPerfil}
                                                                            >
                                                                                <ChevronDoubleUpIcon
                                                                                    className={`${arrowButtonPerfil ? 'transform rotate-180' : ''} w-5 h-5 text-gray-500`}
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    {!arrowButtonPerfil && arrowButton &&
                                                                        <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Nome
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        value={nome}
                                                                                        onChange={e => setNome(e.target.value)}
                                                                                        className="p-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorNome === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o nome.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    CEP
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        value={cep}
                                                                                        onChange={e => {
                                                                                            setCep(formatarCep(e.target.value))
                                                                                        }}
                                                                                        className="p-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorCep === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o CEP.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Endereço
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        readOnly={true}
                                                                                        value={endereco}
                                                                                        onChange={e => setEndereco(e.target.value)}
                                                                                        className="p-4  bg-gray-200 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorEndereco === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o endereço.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Número
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        value={numeroResidencia}
                                                                                        onChange={e => {
                                                                                            const onlyNumber = e.target.value.replace(/\D/g, '')
                                                                                            setNumeroResidencia(onlyNumber)
                                                                                        }}
                                                                                        className="p-4   block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorEndereco === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o número e o complemento da sua residencia.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Cidade
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        value={cidade}
                                                                                        onChange={e => setCidade(e.target.value)}
                                                                                        className="p-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorCidade === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher a cidade.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Estado
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        value={estado}
                                                                                        onChange={e => setEstado(e.target.value)}
                                                                                        className="p-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorEstado === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o estado.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Telefone
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="email"
                                                                                        name="email"
                                                                                        type="text"
                                                                                        value={telefone}

                                                                                        onChange={e => setTelefone(e.target.value)}
                                                                                        className="p-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorTelefone === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o telefone.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                                                                    Cpf_cnpj
                                                                                </label>
                                                                                <div className="mt-2 sm:col-span-2 sm:mt-0">
                                                                                    <input
                                                                                        id="password"
                                                                                        name="password"
                                                                                        type="text"
                                                                                        autoComplete="password"
                                                                                        placeholder='Digite a senha atual ..'
                                                                                        value={cpf_cnpj}
                                                                                        onChange={e => {
                                                                                            setCpf_cnpj(formatarCpfCnpj(e.target.value))
                                                                                        }}
                                                                                        className="p-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-md sm:text-sm sm:leading-6"
                                                                                    />
                                                                                    {
                                                                                        errorCpf_cnpj === true && (
                                                                                            <p class="mt-2 ml-2 text-pink-600 text-sm">
                                                                                                É necessário preencher o CPF/CNPJ.
                                                                                            </p>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>}
                                                                </div>
                                                            </div>

                                                            {!arrowButtonPerfil &&
                                                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                                                    <button
                                                                        onClick={e => enviarFormPerfil(e)}
                                                                        className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                                                                    >
                                                                        Salvar
                                                                    </button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                )
                            }




                        </div>
                    </div>
                </div>

                <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
            </div>
        </>
    )
}