
import Icon from '../../../assets/user.png'
import { TrashIcon, MagnifyingGlassIcon, PlusIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import './index.css'


const TableMails = ({ data, next, previous, ofsset, limit, add, edit, deleteModal }) => {



    return (
        <div className="px-4 sm:px-6 lg:px-8 border  p-4 rounded-[10px] w-[100%]">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Contos de clientes</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Lista dos contatos recebidos pelo site.
                    </p>
                </div>
                <button className="border  p-2 rounded-[10px] hover:shadow-md" onClick={() => add()}>
                    <PlusIcon className="h-7 w-7 text-black" aria-hidden="true" />
                </button>
            </div>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Título
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Descrição
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Data de criação
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Data da última atualização
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {
                                    data.length === 0 && (
                                        <tr>
                                            <td colSpan="4" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                                                Nenhum registro encontrado
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    data.length > 0 && data.map((person) => {
                                        return (
                                            <tr key={person.email}>
                                                <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                    <div className="flex items-center">
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900">{person.titulo}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                    <div className="parag text-gray-900">{person.descricao}</div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                    <div className="text-gray-900">{moment(person.data_criacao).format('DD/MM/YYYY')}</div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                    <div className="text-gray-900">{moment(person.data_atualizacao).format('DD/MM/YYYY HH:MM:SS')}</div>
                                                </td>
                                                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                    <button className="border  p-2 rounded-[10px] hover:shadow-md" onClick={() => edit(person)}>
                                                        <MagnifyingGlassIcon className="h-7 w-7 text-black" aria-hidden="true" />
                                                    </button>
                                                </td>
                                                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                    <button className="border  p-2 rounded-[10px] hover:shadow-md" onClick={() => deleteModal(person)}>
                                                        <TrashIcon className="h-7 w-7 text-gray-400" aria-hidden="true" />
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-6 px-8">
                            <div className="flex flex-1 justify-between sm:justify-start">
                                <div className="hidden sm:block mt-2">
                                    <p className="text-sm text-gray-700">
                                        Mostrando registros de {ofsset > 0 ? ofsset : 1} a {ofsset + limit}
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-1 justify-start sm:justify-end">
                                <button
                                    className="border border-gray-200 relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                    onClick={() => previous()}
                                >
                                    Anterior
                                </button>
                                <button
                                    href="#"
                                    className="border border-gray-200 relative ml-3 inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 rounded-md hover:bg-gray-200 focus-visible:outline-offset-0"
                                    onClick={() => next()}
                                >
                                    Próximo
                                </button>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TableMails;