


import { PlusIcon, TrashIcon } from '@heroicons/react/24/solid'
import { useState, useEffect, Fragment } from 'react';

const Config = ({ data, upload }) => {

    const [objHome, setObjHome] = useState({})
    const [removedFaqs, setRemovedFaqs] = useState([]);
    const [error, setError] = useState(false);
    const [msgError, setMsgError] = useState('');

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setObjHome(data)
        }
    }, [data])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (objHome.length === 0) {
            setError(true)
            setMsgError('É necessário ter pelo menos uma pergunta e resposta')
            return
        }
        for (const item of objHome) {
            for (const item2 of Object.keys(item)) {
                if (item[item2] === '') {
                    const index = objHome.findIndex((item3) => item3.id === item.id)
                    setError(true)
                    setMsgError(`É necessário preencher todos os campos, o campo ${item2} ${index + 1} está vazio`)
                    return
                }
            }
        }
        const obj = {
            faqs: objHome,
            removedFaqs
        }
        await upload(obj);

    };
    /* FIM DA LOGICA IMPLEMENTADA NA SECAO 6 */

    const updatePergunta = (index, newValue) => {
        const updatedHome = [...objHome];
        updatedHome[index].pergunta = newValue;
        setObjHome(updatedHome);
    };

    const updateResposta = (index, newValue) => {
        const updatedHome = [...objHome];
        updatedHome[index].resposta = newValue;
        setObjHome(updatedHome);
    };

    const addInput = () => {
        const updatedHome = [...objHome];
        updatedHome.push({ pergunta: '', resposta: '' });
        setObjHome(updatedHome);
    }



    return (
        <form className='flex w-full flex-col border  p-8 rounded-[10px]' onSubmit={onSubmit}>
            <div className="space-y-12">
                <div className='flex'>
                    <p className="text-lg font-font-thin leading-6 text-gray-900">Perguntas e respostas cadastradas</p>
                    <button type='submit' className='ml-auto flex items-center justify-center px-4 py-2   text-sm font-medium text-white rounded-[10px] border shadow-sm hover:shadow-md'
                        onClick={addInput}
                    >
                        <PlusIcon className=' h-8 w-8 text-black' aria-hidden="true" />
                    </button>
                </div>
                <hr className='border-gray-900/10' />
                <div className="border-b border-gray-900/10 pb-12">
                    <div className='mt-10 grid grid-cols-2 gap-x-6 gap-y-8 sm:grid-cols-7'>
                        {objHome.length > 0 && objHome.map((item, index) => (
                            <Fragment key={item.id}>
                                <div className='col-span-3'>
                                    <div>
                                        <label htmlFor={`pergunta-${index}`} className="block text-sm font-semibold leading-6 text-gray-900">
                                            Pergunta #{index + 1}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                value={item.pergunta}
                                                onChange={(e) => updatePergunta(index, e.target.value)}
                                                type="text"
                                                placeholder='Pergunta'
                                                name={`pergunta-${index}`}
                                                id={`pergunta-${index}`}
                                                autoComplete="off"
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-3'>
                                    <div>
                                        <label htmlFor={`resposta-${index}`} className="block text-sm font-semibold leading-6 text-gray-900">
                                            Resposta #{index + 1}
                                        </label>
                                        <div className="mt-2.5">
                                            <input
                                                value={item.resposta}
                                                onChange={(e) => updateResposta(index, e.target.value)}
                                                type="text"
                                                placeholder='Resposta'
                                                name={`resposta-${index}`}
                                                id={`resposta-${index}`}
                                                autoComplete="off"
                                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-span-1 mt-7'>
                                    <button type='button' className='flex items-center justify-center px-4 py-2   text-sm font-medium text-white rounded-[10px] border shadow-sm hover:shadow-md'
                                        onClick={() => {
                                            const updatedHome = [...objHome];
                                            if (item.new) {
                                                updatedHome.splice(index, 1);
                                                setObjHome(updatedHome);
                                            } else {
                                                setRemovedFaqs([...removedFaqs, { id: item.id }]);
                                                updatedHome.splice(index, 1);
                                                setObjHome(updatedHome);
                                            }
                                        }}
                                    >
                                        <TrashIcon className=' h-7 w-7 text-black' aria-hidden="true" />
                                    </button>


                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
            </div>
            {
                error && (
                    <div className='mt-4'>
                        <p className='text-red-500 text-sm'>{msgError}</p>
                    </div>
                )
            }
            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button
                    type="submit"
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    {
                        Object.keys(data).length > 0 ? 'Atualizar' : 'Salvar'
                    }
                </button>
            </div>
        </form>
    )


}



export default Config;
