import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import moment from "moment"
import WppBtn from "../../wppbtn"


const acoesJud = () => {
  window.location.href = "https://somaassist.com.br"
}

export default function Footer() {

  const [data, setData] = useState({})

  useEffect(() => {
    const config = JSON.parse(localStorage.getItem('config'))
    setData(config)


  }, [])

  const formatarTelefone = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digitos = `${input}`.replace(/\D/g, '');

    // Verificar o comprimento dos dígitos para formatar adequadamente
    if (digitos.length === 10) { // DDD + Telefone fixo (8 dígitos)
      return digitos.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
    } else if (digitos.length === 11) { // DDD + Telefone móvel (9 dígitos)
      return digitos.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
    } else {
      // Não é um número válido
      return 'Número inválido';
    }
  }

  const formatarCEP = (input) => {
    // Remover todos os caracteres que não sejam dígitos
    const digits = input.replace(/\D/g, '');

    // Aplicar o padrão XXXXX-XXX usando grupos de captura
    const formatted = digits.replace(/(\d{5})(\d{3})/, '$1-$2');

    return formatted;
  }

  const navigation = {
    support: [
      {
        id: 1, name: 'Localização', desc: `${data?.endereco} -  ${data?.cidade} - ${data?.estado} - ${data.cep && formatarCEP(data?.cep)}`,
        icon: () => (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
          </svg>
        )
      },
      {
        id: 3, name: 'Contatos', desc: `(31) 97136-6684      (31) 99753-9083  conntato@sinprev.org.br`,
        icon: () => (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>

        )
      },
    ],
    company: [
      { id: 1, name: 'Página inicial', href: '/' },
      { id: 3, name: 'Quem Somos', href: false },
      { id: 4, name: 'Últimas noticias', href: '#' },
    ],
    legal: [

    ],
    social: [
      {
        id: 1,
        name: 'Facebook',
        href: data?.facebook,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        id: 2,
        name: 'Instagram',
        href: data?.instagram,
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },

    ],
  }

  return (
    <>
      <footer className='bg-gray-800' aria-labelledby="footer-heading">
        {/* <a href={`https://wa.me/${whatsappNumber}?text=${message}`} */}
        <WppBtn numero={data?.whatsapp} />
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          
          <div className="grid grid-cols-3 gap-8">
            <div className="col-span-2 md:grid md:grid-cols-2 md:gap-8">
              <div>
                <div className="flex md:justify-center mb-4 md:mb-0">
                  {navigation.social.map((item) => (
                    <a key={item.id} href={item.href} className="text-gray-400 hover:text-gray-300">
                      <span className="sr-only ">{item.name}</span>
                      <item.icon className="h-12 w-12 mr-2" aria-hidden="true" />

                    </a>
                  ))}
                </div>
              </div>
              <div className="flex sm:flex-row flex-col">
                {
                  navigation.support.map((item) => {
                    if (item.name === 'Contatos') {
                      return (<span key={item.id}>
                        <h3 className="text-base font-medium text-white" >{item.name}</h3>
                        <ul role="list" className="mt-4 space-y-4" >
                          <li className="" >
                            <a href='https://wa.me/5531982075060' className="text-base text-gray-300 hover:text-white text-center ">
                              {formatarTelefone(data.telefone)} - {data.nome} - {data.email}
                            </a>
                            <br />
                          </li>
                        </ul>
                      </span>)
                    } else {
                      return (
                        <span key={item.id} className="mr-12">
                          <h3 className="text-base font-medium text-white " >{item.name}</h3>
                          <ul role="list" className="mt-4 space-y-4" >
                            <li  >
                              <a href={item.href} className="text-base text-gray-300 hover:text-white text-center">
                                {item.desc}
                              </a>
                            </li>
                          </ul>
                        </span>
                      )
                    }

                  }
                  )
                }
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-x-4">
              <div>
                <h3 className="text-base font-medium text-white">Links Rápidos</h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) =>
                    item.href !== false ?
                      (
                        <li key={item.id}>
                          <Link to={item.href} className="text-base text-gray-300 hover:text-white">
                            {item.name}
                          </Link>
                        </li>
                      ) : (
                        <li key={item.id}>
                          <Link onClick={acoesJud} className="text-base text-gray-300 hover:text-white">
                            {item.name}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>
          </div>

          
          <div className="mt-8 border-t border-gray-700 pt-8 md:flex md:flex-col md:items-center md:justify-center">
            <p className="text-[white] mb-5">© {moment().format('YYYY')} Soter Tec. Todos os direitos reservados. </p>
          </div>
        </div>
      </footer>
    </>
  )
}
