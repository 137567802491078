


import { XMarkIcon } from '@heroicons/react/24/solid'
import { useState, useEffect, useCallback } from 'react';
import Arquivos from '../Arquivos'
import Arquivo from '../Arquivo'


const Config = ({ data, upload, post }) => {

    const [errors, setErrors] = useState({
        corPrimaria: false,
        corSecundaria: false,
        corTerciaria: false,
        email: false,
        whatsapp: false,
        facebook: false,
        instagram: false

    })



    const isValidHex = (hex) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);
    const [originalImages, setOriginalImages] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [removedImages, setRemovedImages] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);
    const [error, setError] = useState(false);

    const onDragEnter = () => setIsDragOver(true);
    const onDragLeave = () => setIsDragOver(false);

    const [idSecao, setIdSecao] = useState(0)

    // Carregar imagens iniciais do banco de dados
    useEffect(() => {
        if (data.secao1?.img) {
            setOriginalImages(data.secao1.img);
            setNewImages([]);
            setRemovedImages([]);
            setIdSecao(data.id)
        }
    }, [data]);


    const imageSpaces = () => {
        const allImages = [...originalImages, ...newImages];
        const placeholders = 6 - allImages.length; // Calcula quantos placeholders são necessários

        return (
            <>
                {allImages.map((image, index) => (
                    <div key={index} className="relative">
                        <img
                            src={image instanceof File ? URL.createObjectURL(image) : image.url}
                            alt={`Preview ${index}`}
                            className="h-24 w-full rounded-md object-cover"
                            crossOrigin='anonymous'
                        />
                        <XMarkIcon
                            onClick={() => handleRemoveFile(image, index)}
                            className="absolute top-0 right-0 h-5 w-5 text-red-500 cursor-pointer"
                            aria-hidden="true"
                        />
                    </div>
                ))}
                {Array.from({ length: placeholders }).map((_, index) => (
                    <div key={`placeholder-${index}`} className="h-24 w-full rounded-md object-cover border-2 border-dashed border-gray-300 flex justify-center items-center">
                        {/* Aqui você pode colocar um ícone ou texto indicando que é um placeholder */}
                        <p className="text-gray-500">Adicionar Imagem</p>
                    </div>
                ))}
            </>
        );
    };


    const onDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation(); //

        // Extrair arquivos do evento de drop
        const droppedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;

        // Calcular o número total de imagens após adicionar as novas
        const totalImages = originalImages.length + newImages.length + droppedFiles.length;

        if (totalImages > 6) {
            setError('Pode ter no máximo 6 arquivos adicionados');
        } else {
            // Adicionar novos arquivos à lista de novas imagens
            setNewImages([...newImages, ...Array.from(droppedFiles)]);
        }
    }, [originalImages, newImages]);





    const onDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation(); //
    };

    // // console.log(data.secao3?.numbers[0]?.texto.description)

    // Estados para imagens originais, novas e removidas


    // Atualizar a lista de novas imagens
    const onFileChange = (event) => {
        const totalImages = originalImages.length + newImages.length + event.target.files.length;
        if (totalImages > 6) {
            setError('Pode ter no máximo 6 arquivos adicionados');
        } else {
            // Adicionar novos arquivos à lista de novas imagens
            setNewImages([...newImages, ...Array.from(event.target.files)]);
        }
    };

    // Remover uma imagem
    const handleRemoveFile = (image, index) => {
        if (originalImages.length == 1 && newImages.length == 0) {
            setError('Deve ter pelo menos uma imagem')
            return
        }
        if (originalImages.includes(image)) {
            if (removedImages.length == 0) {
                setRemovedImages([image]);
            } else {
                setRemovedImages([...removedImages, image]);
            }
            setOriginalImages(originalImages.filter((_, idx) => idx !== index));
        } else {
            setNewImages(newImages.filter((_, idx) => idx !== index));
        }
    };

    // Preparar os dados para envio
    const onSubmit = async (e) => {
        e.preventDefault();
        if (newImages.length == 0 && removedImages.length == 0) {
            setError('Não ocorreu nenhuma alteração nas imagens')
            return
        }
        if ((newImages.length + originalImages.length) > 6) {
            setError('Pode ter no máximo 6 arquivos adicionados')
            return

        }
        const updatedImages = {
            id: idSecao,
            added: newImages,
            removed: removedImages,
            kept: originalImages
        };
        const formData = new FormData();
        formData.append('id', idSecao)
        newImages.forEach((file) => {
            formData.append('images', file); // 'file' é um objeto File
        });

        // Adicionando IDs das imagens removidas
        formData.append('removed', JSON.stringify(removedImages)); // Supondo que 'image' tem um 'id'


        // Adicionando IDs das imagens mantidas
        originalImages.forEach((image) => {
            formData.append('kept', image.id); // Da mesma forma aqui
        });


        await upload(formData, idSecao)

    };




    return (
        <form className='flex w-full flex-col' onSubmit={onSubmit}>
            <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-12">
                    {/* SECAO 01 */}
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-6">
                            <h1 htmlFor="cover-photo" className="block text-sm  leading-6 text-gray-900">
                                Seção 01 - Imagens
                            </h1>
                            <div className="mt-2 flex justify-center w-[100%]">
                                <div className="mt-2 grid grid-cols-3 gap-4  ">
                                    {imageSpaces()}
                                </div>
                            </div>

                            {
                                newImages.length > 0 && (
                                    <div className="mt-2 flex items-center gap-x-3">
                                        <div className="flex flex-col gap-y-2">
                                            <div className="flex items-center gap-x-2">
                                                <span className="text-sm font-medium leading-6 text-gray-900">
                                                    {newImages[0].name}
                                                </span>
                                                <XMarkIcon onClick={() => setFiles([])} className="h-5 w-5 text-red-500 cursor-pointer" aria-hidden="true" />
                                            </div>
                                            <span className="text-sm leading-6 text-gray-500">
                                                {newImages[0].size} bytes
                                            </span>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                error != '' && <p className="text-red-500 text-xs mt-1">{error}</p>
                            }
                            <div className={`text-center ${isDragOver ? 'border-indigo-600' : 'border-gray-900/25'} mt-2 flex justify-center rounded-lg border border-dashed px-6 py-10`}
                                onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter}
                                onDragLeave={onDragLeave}>
                                <div className="mt-4 flex text-sm text-center leading-6 text-gray-600">
                                    <label
                                        htmlFor="file-upload"
                                        className="relative cursor-pointer  rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    >
                                        <span>Upload a file</span>
                                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={onFileChange} />
                                    </label>
                                    <p className="pl-1">or drag and drop</p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">PNG, JPG up to 10MB</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                >
                    {
                        Object.keys(data).length > 0 ? 'Atualizar' : 'Salvar'
                    }
                </button>
            </div>
        </form >
    )


}



export default Config;
