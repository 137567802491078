import Header from '../../../components/Header/HeaderDash'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../context'
import Config from '../../../components/config'
import { Bars3Icon } from '@heroicons/react/24/solid'
import LoadingSpinner from "../../../components/spinner"

const Home = () => {
    const [data, setData] = useState({})
    const { states, setters, loadConfig, updateConfigSemArquivo, updateConfigComArquivo, postConfig } = useContext(AuthContext)

    const load = async () => {
        await loadConfig();
    }

    useEffect(() => {
        load();
    }, [])

    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config'))
        if (config != null) {
            setData(config)
        } else {
            setData({})
        }


    }, [states.dataConfig])


    const updateSemArquivo = async (data) => {
        await updateConfigSemArquivo(data)
    }

    const updateComArquivo = async (data, id) => {
        await updateConfigComArquivo(data, id)
    }

    const post = async (data) => {
        await postConfig(data)
    }



    return (
        <div className='flex flex-col  sm:ml-64'>
            <Header />
            <LoadingSpinner />
            <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
                <button
                    type="button"
                    className="-ml-0.5 -mt-0.5 inline-flex  h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setters.setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <div className='flex flex-col'>
                <div className="mx-auto w-full px-4 py-4 sm:px-6 lg:px-8 border">
                    <h1 className="text-lg font-semibold leading-6 text-gray-900">Configurações</h1>
                </div>
                <div className='flex p-6 w-full'>
                    <Config data={states.dataConfig} semArquivo={updateSemArquivo} comArquivo={updateComArquivo} post={post}
                    />
                </div>
            </div>
        </div>
    )

}


export default Home