import Header from '../../../components/Header/HeaderDash'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../context'
import Table from '../../../components/Tables/blog'
import ModalBlog from '../../../components/ModalBlog'
import ModalDelete from '../../../components/modalDelete'
import { Bars3Icon } from '@heroicons/react/24/solid'
import LoadingSpinner from "../../../components/spinner"


const Home = () => {
    const [data, setData] = useState({})
    const { states, setters, getWithLimitBlog, createBlog, getBlogById, editlog, deleteBlog } = useContext(AuthContext)

    const [id, setId] = useState('')


    const load = async () => {
        await getWithLimitBlog();
    }

    useEffect(() => {
        load();
    }, [])

    const next = async () => {
        if (states.limiteBuscaDataBlog) {
            return
        } else {
            setters.setOffsetDataBlog(states.offsetDataBlog + states.limitDataBlog)
        }


    };


    const previous = async () => {
        if (states.offsetDataBlog <= 0) {
            states.offsetDataBlog < 0 && setters.setOffsetDataBlog(0)
            return
        } else {
            if (states.limiteBuscaDataBlog) setters.setLimiteBuscaDataBlog(false)
            setters.setOffsetDataBlog(states.offsetDataBlog - states.limitDataBlog)
        }

    };

    useEffect(() => {
        if (states.dataBlog.length > 0) {
            load()
        }
        //Isso aqui atrapalha a paginacao
    }, [states.offsetDataBlog])
    useEffect(() => {
        setters.setOffsetDataBlog(0)
        //Isso aqui atrapalha a paginacao
    }, [])


    const openModalMail = (data) => {
        setters.setModalMail(true)
        setData(data)
    }

    const openModalSendMail = (data) => {
        setters.setModalSendMail(true)
        setData(data)
    }

    useEffect(() => {
        if (!states.modalMail) {
            setData([])
        }
    }, [states.modalMail])

    useEffect(() => {
        if (!states.modalSendMail) {
            setData([])
        }

    }, [states.modalSendMail])


    const action = async (obj) => {
        await createBlog(obj)
        setters.setModalBlog(false)
    }

    const edit = async (obj, id) => {
        await editlog(obj, id)
        setters.setModalBlog(false)
    }

    const openModalAdd = () => {
        setters.setIsEdit(false)
        setters.setModalBlog(false)
        setters.setModalBlog(true)
    }
    const openModalEdit = async (data) => {
        setters.setIsEdit(true)
        await getBlogById(data.id)
        setData(states.blog)
    }

    useEffect(() => {
        if (states.isEdit) {
            setters.setModalBlog(true)
            setData(states.blog)
        }
    }, [states.blog])

    const openModalDelete = async (data) => {
        setters.setIsEdit(false)
        setters.setBlog(data)
        setters.setModalDelete(true)
    }
    const deleteBlogFunction = async () => {
        setters.setModalDelete(false)
        await deleteBlog(states.blog.id)
    }



    return (
        <>
            <div className='flex flex-col  sm:ml-64'>
                <Header />
                <LoadingSpinner />
                <button
                    type="button"
                    className=" -ml-0.5 -mt-0.5 md:hidden inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setters.setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className='flex flex-col'>
                    <div className="mx-auto w-full px-4 py-4 sm:px-6 lg:px-8 border">
                        <h1 className="text-lg font-semibold leading-6 text-gray-900">Blog do Site</h1>
                    </div>
                    <div className='flex p-8 w-[100%] '>
                        <Table data={states.dataBlog} next={next} previous={previous} open={openModalMail} openSend={openModalSendMail}
                            ofsset={states.offsetDataBlog} limit={states.limitDataBlog} add={openModalAdd} edit={openModalEdit} deleteModal={openModalDelete} />
                    </div>
                </div>
            </div>
            <ModalBlog data={data} states={states} setters={setters} action={action} edit={edit} />
            <ModalDelete states={states} setters={setters} action={deleteBlogFunction} />
        </>
    )

}


export default Home