import { useState, useCallback } from 'react';

import { PhotoIcon } from '@heroicons/react/24/solid'
import { useEffect } from 'react';

const Arquivo = ({ files, setFiles }) => {

    const [error, setError] = useState('');
    const onFileChange = (event) => {
        if (files.length >= 6) {
            setError('Pode ter no máximo 6 arquivos adicionados')
        } else {
            setFiles([...event.target.files]);
        }
    };

    const onDrop = useCallback((event) => {
        event.preventDefault();
        if (files.length >= 6) {
            setError('Pode ter no máximo 6 arquivos adicionados')
        } else {
            setFiles([...event.target.files]);
        }
    }, []);





    const onDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <div className="text-center" onDrop={onDrop} onDragOver={onDragOver}>
            <div className="mt-4 flex text-sm text-center leading-6 text-gray-600">
                <label
                    htmlFor="file-upload"
                    className="relative cursor-pointer  rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                >
                    <span>Upload a file</span>
                    <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={onFileChange} />
                </label>
                <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs leading-5 text-gray-600">PNG, JPG up to 10MB</p>

            {
                error != '' && <p className="text-red-500 text-xs mt-1">{error}</p>
            }
        </div>
    );
}


export default Arquivo;