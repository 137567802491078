import { useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../../components/Footer/Foooter";
import Header from "../../../components/Header/Header";
import { AuthContext } from "../../../context";
import ErrorSite from '../../../components/errorSite'



const Blogs = () => {



    const { states, setters, confirmarEmail } = useContext(AuthContext)
    const { token } = useParams();


    const confirm = async () => {
        await confirmarEmail(token)
        navigate('/login')
    }



    const navigate = useNavigate();
    const login = () => {
    }

    const dataTable = {
        data: states.dataBlog,
    }

    return (
        <>
            <Header />
            <ErrorSite />
            <div className="bg-white px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
                <div className="flex justify-center mx-auto max-w-lg divide-y-2 divide-gray-200 lg:max-w-7xl ">

                    <div className="bg-blue-700 rounded-2xl w-[60%] pt-7 pb-7">
                        <div className="flex justify-center">
                            <div className="flex flex-col items-center ">
                                <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">Confirmaçao de e-mail</h2>
                                <p className="mt-3 max-w-3xl text-lg leading-6 text-blue-200">
                                    Clique no botão para autenticar seu e-mail
                                </p>
                                <button
                                    onClick={confirm}
                                    className="mt-5 inline-flex items-center rounded-md border border-transparent bg-white px-3 py-2 text-sm font-medium leading-4 text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                                    Confirmar e-mail
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );

}


export default Blogs;