import { Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../context";
import Sidebar from "../components/sideBar";
import Loading from "../components/Load";


const RouterWrapper = ({ component: Component, isPrivate }) => {
    const { states, setters, checkLogin, loadConfig } = useContext(AuthContext)


    async function isSigned() {
        if (localStorage.getItem('token') != null) setters.setSigned(true)
        if (isPrivate) {
            const response = await checkLogin()
            if (response === true) {
                setters.setSigned(true)
            } else {
                // // localstorage.clear()
                setters.setSigned(false)
            }
        }
    }
    const loadConfigFunction = async () => {
        if (Object.keys(states.dataConfig).length === 0) {
            await loadConfig()
        }
    }

    useEffect(() => {
        isSigned()
    }, [localStorage.getItem('token')])

    useEffect(() => {
        if (!states.errorConfig) {
            loadConfigFunction()
        }
    }, [states.dataConfig])

    if (isPrivate) {
        if (localStorage.getItem('token') != null) {
            return (
                <>
                    <Sidebar />
                    <Component />
                </>
            )
        } else if (localStorage.getItem('token') === null) {
            return (
                <Navigate to='/' />
            )
        } else {
            localStorage.setItem('token', localStorage.getItem('token'))
            return (
                <>
                    <Sidebar />
                    <Component />
                </>
            )
        }

    }

    if (isPrivate && !states.signed) {
        return (
            <Navigate to='/' />
        )
    }
    if (states.isLoading == true) {
        return (<Loading />)
    } else {
        if (!isPrivate) {
            return (
                <Component />
            )
        }
        return (
            <Component />
        )
    }




}

export default RouterWrapper;