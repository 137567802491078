
import Header from '../../../components/Header/HeaderDash'
import { Link } from "react-router-dom";
import { Bars3Icon } from '@heroicons/react/24/outline'
import { AuthContext } from '../../../context';
import { useContext } from 'react';

const AdminIndex = () => {

    const { setters } = useContext(AuthContext)

    return (
        <>
            <div className="flex flex-1 flex-col pb-16 bg-white">
                <div className='flex flex-1 flex-col md:pl-64 mb-4'>
                    <Header />
                    <button
                        type="button"
                        className="-ml-0.5 -mt-0.5 inline-flex sm:hidden h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                        onClick={() => setters.setSidebarOpen(true)}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <hr className="border-1 border-gray-200" />
                    <div className="p-4">
                        <div className="px-4 sm:px-6 lg:px-8">
                            <div className="mb-4 border flex-col flex w-full md:w-[100%] items-center justify-center p-4 border border-1 border-gray-200 rounded-md overflow-hidden bg-white">
                                <h1 className="text-3xl text-center font-bold">Painel da página incial</h1>
                                <p className="text-[18px] mt-1 text-center">Aqui você terá acesso as configurações da página home do seu site</p>
                            </div>
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                                {/* Card */}
                                <div className="flex items-center justify-center">
                                    <Link to="/homePagina/secao1" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <Bars3Icon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold">Seção #1</h2>
                                        <p className="text-center">Carrossel de imagens</p>
                                    </Link>
                                </div>

                                <div className="flex items-center justify-center">
                                    <Link to="/homePagina/secao2" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <Bars3Icon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold text-center">Seção #2</h2>
                                        <p className="text-center">Chamada para contato com foto</p>
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/homePagina/secao3" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <Bars3Icon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold">Seção #3</h2>
                                        <p className="text-center">Imagem de fundo com números e diferenciais</p>
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/homePagina/secao4" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <Bars3Icon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold">Seção #4</h2>
                                        <p className="text-center">Icones com diferenciais</p>
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/homePagina/secao5" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <Bars3Icon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold">Seção #5</h2>
                                        <p className="text-center">Chamada para contato</p>
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/homePagina/secao6" className="w-full h-[100%] text-black border p-4 flex items-center justify-center flex-col border border-1 border-gray-200 rounded-md overflow-hidden bg-white hover:shadow-md">
                                        <Bars3Icon className="h-[30%] w-[30%] text-blue-600" />
                                        <h2 className="text-xl font-bold">Seção #6</h2>
                                        <p className="text-center">Banners para breading</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="pt-8">
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default AdminIndex;
