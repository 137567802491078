import { Fragment, useRef, useState, useEffect, useCallback } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const ModalBlog = ({ states, setters, data, action, edit }) => {
    const [open, setOpen] = useState(true)

    const cancelButtonRef = useRef(null)

    const [objHome, setObjHome] = useState({})

    useEffect(() => {
        if (Object.keys(data).length > 0) {
            setObjHome(data)
        }
    }, [data])

    // // console.log(data.secao3?.numbers[0]?.texto.description)

    /* LOGICA IMPLEMENTADA SECAO 6 */
    const [originalImages, setOriginalImages] = useState([]);
    const [newImages, setNewImages] = useState([]);
    const [removedImages, setRemovedImages] = useState([]);
    const [isDragOver, setIsDragOver] = useState(false);
    const [idSecao, setIdSecao] = useState('');
    const [error, setError] = useState(false);
    const [temImagem, setTemImagem] = useState(false);
    const [title, setTitle] = useState('');
    const [descricao, setDescricao] = useState('');

    const onDragEnter = () => setIsDragOver(true);
    const onDragLeave = () => setIsDragOver(false);
    const isValidHex = (hex) => /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex);


    // Carregar imagens iniciais do banco de dados
    useEffect(() => {
        if (states.isEdit || Object.keys(data).length > 0) {
            setTitle(data.titulo)
            setDescricao(data.descricao)
            if (data.imagem != null) {
                setTemImagem(true)
                setOriginalImages([{ url: data.arquivo }])
            } else {
                setOriginalImages([])
            }
        }
    }, [data]);


    const imageSpaces = () => {
        const allImages = [...originalImages, ...newImages];
        const placeholders = 1 - allImages.length; // Calcula quantos placeholders são necessários

        return (
            <>
                {allImages.map((image, index) => {
                    return (
                        <div key={index} className="relative">
                            <img
                                className='w-[120px] h-full rounded-md object-contain border-2 border-dashed border-gray-300'
                                src={image instanceof File ? URL.createObjectURL(image) : image.url}
                                alt={`Preview ${index}`}
                                crossOrigin='anonymous'
                            />
                            <XMarkIcon
                                onClick={() => handleRemoveFile(image, index)}
                                className="absolute top-0 right-0 h-5 w-5 text-red-500 cursor-pointer"
                                aria-hidden="true"
                            />
                        </div>
                    )
                })}
                {Array.from({ length: placeholders }).map((_, index) => (
                    <div key={`placeholder-${index}`} className=" w-[115px] rounded-md object-contain border-2 border-dashed border-gray-300 flex justify-center items-center">
                        {/* Aqui você pode colocar um ícone ou texto indicando que é um placeholder */}
                        <p className="text-gray-500">Adicionar Imagem</p>
                    </div>
                ))}
            </>
        );
    };


    const onDrop = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation(); //

        // Extrair arquivos do evento de drop
        const droppedFiles = event.dataTransfer ? event.dataTransfer.files : event.target.files;

        // Calcular o número total de imagens após adicionar as novas
        const totalImages = originalImages.length + newImages.length + droppedFiles.length;

        if (totalImages > 1) {
            setError('Pode ter no máximo 1 arquivo adicionado.');
        } else {
            // Adicionar novos arquivos à lista de novas imagens
            setNewImages([...newImages, ...Array.from(droppedFiles)]);
        }
    }, [originalImages, newImages]);





    const onDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation(); //
    };

    // // console.log(data.secao3?.numbers[0]?.texto.description)

    // Estados para imagens originais, novas e removidas


    // Atualizar a lista de novas imagens
    const onFileChange = (event) => {
        const droppedFiles = event.target.files;
        const totalImages = originalImages.length + newImages.length + droppedFiles.length;
        if (totalImages > 1) {
            setError('Pode ter no máximo 1 arquivo adicionado.');
        } else {
            // Adicionar novos arquivos à lista de novas imagens
            setNewImages([...newImages, ...Array.from(droppedFiles)]);
        }
    };

    // Remover uma imagem
    const handleRemoveFile = (image, index) => {
        if (originalImages.includes(image)) {
            if (removedImages.length == 0) {
                setRemovedImages([image]);
            } else {
                setRemovedImages([...removedImages, image]);
            }
            setOriginalImages(originalImages.filter((_, idx) => idx !== index));
        } else {
            setNewImages(newImages.filter((_, idx) => idx !== index));
        }
    };

    const onSubmit = async (e) => {

        if (!states.isEdit) {
            if (!temImagem) {
                const obj = {
                    titulo: title,
                    descricao,
                }
                if (title == '' || descricao == '') {
                    setError('Preencha todos os campos')
                    return

                }
                await action(obj)
            } else {
                if ((newImages.length + originalImages.length) > 1) {
                    setError('Só pode ter no máximo 1 imagem')
                    return

                }
                const formData = new FormData();
                // formData.append('id', idSecao)
                newImages.forEach((file) => {
                    formData.append('imagem', file); // 'file' é um objeto File
                });
                formData.append('titulo', title)
                formData.append('descricao', descricao)
                await action(formData)
            }

        } else {
            if (!temImagem) {
                const obj = {
                    titulo: title,
                    descricao,
                }
                if (title == '' || descricao == '') {
                    setError('Preencha todos os campos')
                    return

                }
                await edit(obj, states.blog.id)
                return;
            } else {
                if (newImages.length == 0) {
                    const obj = {
                        titulo: title,
                        descricao,
                        imagem: states.blog.imagem
                    }
                    if (title == '' || descricao == '') {
                        setError('Preencha todos os campos')
                        return

                    }
                    await edit(obj, states.blog.id)
                    return;

                }
                if (newImages.length == 0) {
                    setError('Adicione uma imagem')
                    return
                }
                if (newImages.length > 1) {
                    setError('Só pode ter no máximo 1 imagem')
                    return
                }
                const formData = new FormData();
                newImages.forEach((file) => {
                    formData.append('imagem', file); // 'file' é um objeto File
                });
                formData.append('titulo', title)
                formData.append('descricao', descricao)
                await edit(formData, states.blog.id)
                return;
            }
        }

    };

    useEffect(() => {
        if (states.modalBlog == false) {
            setDescricao('')
            setTitle('')
            setOriginalImages([])
            setTemImagem(false)
            setters.setBlog({})
            setters.setIsEdit(false)
        } else {
            if (states.isEdit && Object.keys(states.blog).length > 0) {
                setTitle(states.blog.titulo)
                setDescricao(states.blog.descricao)
                if (states.blog.imagem != null) {
                    setOriginalImages([{ url: states.blog.arquivo }])
                }
            }
            if (!states.isEdit && Object.keys(states.blog).length > 0 && states.modalBlog) {
                setters.setIsEdit(true)
                setTitle(states.blog.titulo)
                setDescricao(states.blog.descricao)
                if (states.blog.imagem != null) {
                    setOriginalImages([{ url: states.blog.arquivo }])
                }

            }
        }
    }, [states.modalBlog]);

    return (
        <Transition.Root show={states.modalBlog} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setters.setModalBlog}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            {/* <Dialog.Panel className="relative transform  overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"> */}
                            <Dialog.Panel className="relative transform  overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg  p-3">
                                <div className="sm:flex sm:items-start ">

                                    <div className=''>
                                        <div className=''>
                                            <h1 className="text-lg font-semibold leading-6 text-gray-900">Blog</h1>
                                            <p className="mt-1 text-sm text-gray-500">Adicione aqui postagens para o seu blog</p>
                                        </div>
                                        <hr className="mt-4" />
                                        <div className="mt-6">
                                            <div className='flex items-center ml-1'>
                                                <input type='checkbox' className="form-checkbox h-4 w-4 text-indigo-600"
                                                    value={temImagem} onChange={(e) => setTemImagem(!temImagem)}
                                                    checked={temImagem}
                                                />
                                                <p className="text-sm font-semibold leading-6 text-gray-900 ml-2">{
                                                    temImagem ? 'Remover imagem' : 'Adicionar imagem'
                                                }</p>
                                            </div>


                                            {
                                                temImagem && (

                                                    <div className="mt-2 flex justify-center w-[100%]">
                                                        <div className="h-[200px] mt-2 w-[50%] flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                                            <div className={`text-center ${isDragOver ? 'border-indigo-600' : 'border-gray-900/25'} mt-2 flex justify-center w-full rounded-lg border border-dashed px-6 py-10`}
                                                                onDrop={onDrop} onDragOver={onDragOver} onDragEnter={onDragEnter}
                                                                onDragLeave={onDragLeave}>
                                                                <div className=" flex- text-sm text-center leading-6 text-gray-600 ">
                                                                    <label
                                                                        htmlFor="file-upload"
                                                                        className="relative cursor-pointer  rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                                                    >
                                                                        <span>Upload a file</span>
                                                                        <p className="text-xs leading-5 text-gray-600">PNG, JPG up to 10MB</p>
                                                                        <p className="hidden sm:fixed text-xs leading-5 text-gray-600">Arraste e solte </p>
                                                                        <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={onFileChange} />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="mt-2 grid grid-cols-1 gap-1 ml-6 w-[50%] h-full ">
                                                            {imageSpaces()}
                                                        </div>
                                                    </div>
                                                )
                                            }


                                            {
                                                error != '' && <p className="text-xs leading-5 text-red-600">{error}</p>
                                            }
                                        </div>

                                        <div className='border rounded-[10px] mt-2 p-4  '>
                                            {/* Link, subtitulo e texto */}
                                            <div className="mt-6 p-2 rounded-[10px] border">
                                                <label htmlFor="about" className="block text-sm font-medium text-gray-900">
                                                    Título:
                                                </label>
                                                <div className="">
                                                    <input
                                                        type="text"
                                                        maxLength={200}
                                                        name="subtitulo"
                                                        id="subtitulo"
                                                        value={title}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        className="shadow-sm  block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                            <div className="mt-4 p-2 rounded-[10px] border">
                                                <label htmlFor="about" className="block text-sm font-medium text-gray-900">
                                                    Publicação:
                                                </label>
                                                <div className="mt-1">
                                                    <textarea
                                                        type="text"
                                                        name="texto"
                                                        maxLength={200}
                                                        id="texto"
                                                        value={descricao}
                                                        onChange={(e) => setDescricao(e.target.value)}
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                                        onClick={() => onSubmit()}
                                    >
                                        {
                                            states.isEdit ? 'Editar' : 'Adicionar'
                                        }
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => setters.setModalBlog(false)}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}


export default ModalBlog;