import Header from '../../../components/Header/HeaderDash'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../context'
import Table from '../../../components/Tables/emails'
import ModalMail from '../../../components/ModalMail'
import ModalSendMail from '../../../components/ModalSendMail'
import { Bars3Icon } from '@heroicons/react/24/solid'
import LoadingSpinner from "../../../components/spinner"


const Home = () => {
    const [data, setData] = useState({})
    const { states, setters, loadEmailsRecebidos, enviarEmail, searchEmailsRecebidos } = useContext(AuthContext)
    const [search, setSearch] = useState('')

    const load = async () => {
        await loadEmailsRecebidos();
    }

    useEffect(() => {
        load();
    }, [])

    // useEffect(() => {
    //     setData(states.dataFaq)


    // }, [states.dataFaq])




    // const upload = async (faqs) => {
    //     await updateDataFaq(faqs)
    // }


    const next = async () => {
        if (states.limiteBuscaDataEmails) {
            return
        } else {
            setters.setOffsetDataEmails(states.offsetDataEmails + states.limitDataEmails)
        }


    };


    const previous = async () => {
        if (states.offsetDataEmails <= 0) {
            states.offsetDataEmails < 0 && setters.setOffsetDataEmails(0)
            return
        } else {
            if (states.limiteBuscaDataEmails) setters.setLimiteBuscaDataEmails(false)
            setters.setOffsetDataEmails(states.offsetDataEmails - states.limitDataEmails)
        }

    };

    useEffect(() => {
        if (states.dataEmails.length > 0 && states.offsetDataEmails > 0) {
            load()
        }
        //Isso aqui atrapalha a paginacao
    }, [states.offsetDataEmails])
    useEffect(() => {
        setters.setOffsetDataEmails(0)
        //Isso aqui atrapalha a paginacao
    }, [])


    const openModalMail = (data) => {
        setters.setModalMail(true)
        setData(data)
    }

    const openModalSendMail = (data) => {
        setters.setModalSendMail(true)
        setData(data)
    }

    useEffect(() => {
        if (!states.modalMail) {
            setData([])
        }
    }, [states.modalMail])

    useEffect(() => {
        if (!states.modalSendMail) {
            setData([])
        }

    }, [states.modalSendMail])


    const sendMail = async (obj) => {
        await enviarEmail(obj)
    }

    const searchEmails = async () => {
        if (search == '') return;
        await searchEmailsRecebidos(search)
        setSearch('')
    }

    return (
        <>
            <div className='flex flex-col  sm:ml-64'>
                <Header />
                <LoadingSpinner />
                <button
                    type="button"
                    className="-ml-0.5 -mt-0.5 inline-flex h-12 sm:hidden w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setters.setSidebarOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
                <div className='flex flex-col'>
                    <div className="mx-auto w-full px-4 py-4 sm:px-6 lg:px-8 border">
                        <h1 className="text-lg font-semibold leading-6 text-gray-900">E-mails recebidos pelo site</h1>
                    </div>
                    {/* CAMPO DE BUSCA */}
                    <div className='flex flex-col sm:flex-row sm:justify-between items-center w-full px-4 py-4 sm:px-6 lg:px-8 border'>
                        <label htmlFor="search" className="block text-sm font-medium text-gray-700 mr-4">
                            Digite o e-mail ou titulo do e-mail para buscar
                        </label>
                        <div className='flex w-[70%] flex-row items-center mt-5 sm:mt-0'>
                            <input
                                type="text"
                                name="search"
                                id="search"
                                className="focus:ring-indigo-500 border p-2  focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="Digite aqui"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />
                            <button
                                type="button"
                                className="ml-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                onClick={() => searchEmails()}
                            >
                                Buscar
                            </button>
                        </div>
                    </div>
                    <div className='flex p-8 w-[100%] '>

                        <Table data={states.dataEmails} next={next} previous={previous} open={openModalMail} openSend={openModalSendMail}
                            ofsset={states.offsetDataEmails} limit={states.limitDataEmails}
                        />


                    </div>
                </div>
            </div>
            <ModalMail states={states} setters={setters} data={data} />
            <ModalSendMail states={states} setters={setters} data={data} send={sendMail} />
        </>
    )

}


export default Home