import Header from '../../../components/Header/HeaderDash'
import { useState, useEffect, useContext } from 'react'
import { AuthContext } from '../../../context'
import { Bars3Icon } from '@heroicons/react/24/solid'
import Config from '../../../components/FaqPanel'
import LoadingSpinner from "../../../components/spinner"

const Home = () => {
    const [data, setData] = useState({})
    const { states, setters, loadDataFaq, updateDataFaq } = useContext(AuthContext)

    const load = async () => {
        await loadDataFaq();
    }

    useEffect(() => {
        load();
    }, [])

    useEffect(() => {
        setData(states.dataFaq)


    }, [states.dataFaq])




    const upload = async (faqs) => {
        await updateDataFaq(faqs)
    }


    return (
        <div className='flex flex-col  sm:ml-64'>
            <Header />
            <LoadingSpinner />
            <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 sm:hidden items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setters.setSidebarOpen(true)}
            >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className='flex flex-col'>
                <div className="mx-auto w-full px-4 py-4 sm:px-6 lg:px-8 border">
                    <h1 className="text-lg font-semibold leading-6 text-gray-900">Configuração das perguntas frequentes</h1>
                </div>
                <div className='flex p-8 w-full '>
                    <Config data={states.dataFaq} upload={upload} />

                </div>
            </div>
        </div>
    )

}


export default Home