import AuthProvider from './context'
import { BrowserRouter } from 'react-router-dom'
import Router from './routes'
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from 'react-toastify';


export default function App() {

  return (
    <div className='notranslate'>
      <AuthProvider>
        <BrowserRouter>
          <ToastContainer autoClose={3000} />
          <Router />
        </BrowserRouter>
      </AuthProvider>
    </div>
  )
}