import { Link } from 'react-router-dom';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Foooter';
import { AuthContext } from '../context';
import { useContext, useEffect } from 'react';
import Loaging from '../components/Load'

import ErrorSite from '../components/errorSite'
export default function pageNotFound() {

  const { states, loadDataNotFound } = useContext(AuthContext)


  const load = async () => {
    await loadDataNotFound()

  }


  useEffect(() => {
    load()
  }, [])

  if (states.loading) {
    return <Loaging />

  }

  if (Object.keys(states.dataNaoEncontrado).length > 0) {

    return (
      <>
        <Header />
        <ErrorSite />
        <div className="pt-8 mt-8 pb-8 mb-8flex min-h-full flex-col bg-white lg:relative">
          <div className="flex flex-grow flex-col">
            <main className="flex flex-grow flex-col bg-white">
              <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-6 lg:px-8">
                <div className="flex-shrink-0 pt-10 sm:pt-16">

                </div>
                <div className="my-auto flex-shrink-0 py-16 sm:py-32">
                  <p className="text-base font-semibold text-blue-600 text-[50px]">{states.dataNaoEncontrado.titulo}</p>
                  <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl w-[500px]">{states.dataNaoEncontrado.descricao}</h1>
                  <p className="mt-2 text-base text-gray-500">{states.dataNaoEncontrado.subDescricao}</p>
                  <div className="mt-6">
                    <Link to="/" className="text-base font-medium text-blue-600 hover:text-blue-500">
                      Voltar para página inicial
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </main>
            <footer className="flex-shrink-0 bg-gray-50">
              <div className="mx-auto w-full max-w-7xl py-16 px-6 lg:px-8">
                <nav className="flex space-x-4">

                </nav>
              </div>
            </footer>
          </div>
          <div className="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:block lg:w-1/2">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src={states.dataNaoEncontrado.arquivo}
              crossOrigin='anonymous'
            />
          </div>
        </div>
        <Footer />
      </>
    )
  }

}
