import Footer from "../../../components/Footer/Foooter";
import Header from "../../../components/Header/Header";
import { Link } from "react-router-dom";
import { useEffect, useContext, useState } from "react";
import { AuthContext } from '../../../context'
import Contato from "../../../components/Contato";
import Loaging from '../../../components/Load';


export default function About() {

    const { states, loadDataContato } = useContext(AuthContext)

    const [data, setData] = useState({})
    const [rgba1, setRgba1] = useState('')
    const [rgba2, setRgba2] = useState('')
    const [rgba3, setRgba3] = useState('')

    const hexToRGBA = (hex, alpha) => {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    useEffect(() => {
        const config = JSON.parse(localStorage.getItem('config'))
        setData(config)
        setRgba1(hexToRGBA(config.corPrimaria, 0.5))
        setRgba2(hexToRGBA(config.corSecundaria, 0.5))
        setRgba3(hexToRGBA(config.corTerciaria, 0.5))

    }, [])

    const load = async () => {
        await loadDataContato()
    }

    useEffect(() => {
        load()
    }, [])

    if (states.loading) {
        return <Loaging />
    }

    if (Object.keys(states.dataContato).length > 0) {
        return (
            <div>
                <Header />
                <div className="flex flex-col justify-center items-center p-20"  style={{
                        height: '100px',
                        background: `${rgba1}`
                }}>
                    <h1 className="flex flex-col items-center justify-center font-['Be Vietnam Bold'] font-bold text-white md:text-[50px] text-[30px]">Contato</h1>
                    <div className="flex flex-row bg">
                        <Link to="/" className="text-gray-200">Página inicial</Link>
                        <span className="text-white"> &rsaquo; Contato </span>
                    </div>
                </div>

                <Contato corPrimaria={rgba1} url={states.dataContato.url} />

                <Footer />
            </div >
        )
    }

}