import { useNavigate } from "react-router-dom"
import { useState, useContext } from "react"
import moment from "moment"
import { AuthContext } from "../../../context"


const HeaderDash = () => {
    // const { logOut } = useContext(AuthContext)
    const navigate = useNavigate()
    const dataInit = moment(new Date()).format('DD/MM/YYYY HH:mm:ss')
    const [date, setDate] = useState(dataInit)


    setInterval(() => {
        machineClock()
    }, 1000)

    const machineClock = () => {
        setDate(moment(new Date()).format('DD/MM/YYYY HH:mm:ss'))
    }


    const logOutSys = async () => {
       // await logOut()
        navigate('/login')
    }

    return (
        <nav
            className="relative w-full flex flex-wrap items-center justify-between py-3 bg-blue-600 text-gray-200 shadow-lg navbar navbar-expand-lg navbar-light"
        >
            <div className="ml-6">
                {date}
            </div>

            <div>
                <button onClick={logOutSys} className="mr-8 rounded-md  inline-flex items-center justify-center  hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                </button>
            </div>


        </nav>
    )

}


export default HeaderDash