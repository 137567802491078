import { CLIENT, URLAPI } from '../certs/config'
import axios from 'axios';


const req = {
    baseURL: URLAPI,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'cliente_id': CLIENT,
    }
}


const clienteAxios = axios.create(req);


export default clienteAxios;
